<template>
<div>
    <Breadcrumbs main="BNPS" submain="Internal Activity Management" title="Pending Drafts" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">
                        <b-row>
                            <b-col xl="6">

                                <b-input-group class="datatable-btn">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>

                            </b-col>

                            <b-col xl="6">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                    <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="draft_list" :fields="tablefields" :current-page="currentPage" :filter="filter" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(activity_name)="data">
                                       <router-link :to="{ name: 'DraftEdit', query: { draft: data.item.id, form:data.item.activity.activity.id, location:data.item.activity.activity.location.id} }" class="link-style">{{ data.item.activity_name }} - {{  data.item.activity.activity.location.name }}</router-link>
                                    </template>


                                  
                                    <template #cell(status)="data">
                                        <span @click="getStatus(data.item.status,data.item.id)"  v-if="data.item.status == 'pending'">
                                            <b-badge v-b-modal.modal-prevent-closing  pill variant="warning" style="text-transform: uppercase;">{{data.item.status}}</b-badge>
                                        </span>
                                        <span @click="getStatus(data.item.status,data.item.id)"  v-if="data.item.status == 'approved'">
                                            <b-badge v-b-modal.modal-prevent-closing  pill variant="success" style="text-transform: uppercase;">{{data.item.status }}</b-badge>
                                        </span>
                                       
                                    </template>

                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <div class="loader-box">
                                <div class="loader-10"></div>
                            </div>
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->
    <!-- Container-fluid Ends-->
    <b-modal id="modal-prevent-closing" ref="modal" title="Update Draft Status"  @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
            <b-form-group label="Status" label-for="name-input">
                <b-form-select v-model="selectedStatus" :options="statuses.map(status => ({ text: status.name, value: status.value }))" value-field="value" text-field="text">
                </b-form-select>
            </b-form-group>
        </form>
    </b-modal>

</div>
</template>

    
<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';

export default {
    data() {
        return {
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'activity_name',
                    label: 'Title',
                    sortable: true
                },
                {
                    key: 'status',
                    label: 'Status',
                    sortable: true
                }

            ],
            statuses: [{
                    name: 'PENDING',
                    value: 'pending'
                },
                {
                    name: "APPROVED",
                    value: 'approved'
                }
            ],
            selectedStatus:null,
            draft_id:null,

       


            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

        };
    },

    computed: {
        ...mapState({
            draft_list: state => state.bnps_project.pending_drafts,
            loading: state => state.bnps_project.submitLoading,
            error: state => state.bnps_project.submitError,
        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {

        this.$store.dispatch("bnps_project/fetchPendingDraftsList", {}).then(() => {
            this.items = this.draft_list;
            this.totalRows = this.items.length;

        });

    },
    watch: {
        draft_list(newValue,oldValue){}
    },

    methods: {
        onFiltered(filteredItems) {

            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        getStatus(status,id) {
            this.draft_id = id
            this.selectedStatus = status

        },
        handleOk(bvModalEvent) {
            bvModalEvent.preventDefault()
            this.handleSubmit()
        },
        handleSubmit() {
            this.$store.dispatch("bnps_project/updateDraftStatus", {
                id: this.draft_id,
                status: this.selectedStatus,
                from_pending:true,
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
            // Hide the modal manually
            this.$nextTick(() => {
                this.$bvModal.hide('modal-prevent-closing')
            })
        },

    }
};
</script>
