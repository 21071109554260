import axios from 'axios';
import {V1API} from "../../../_helpers/APIPath";
import {encode, decode} from "../../dataMagic";
import {populateStore, performNetworkCall, buildFormData} from "@/store/asyncHelper";


const keyList = {
    smsUpdate : 'PROJECT_UPDATE',
    smsDelete : 'PROJECT_DELETE'
};

const mutations = {};
const store = {};

populateStore(store, mutations, keyList, false);

export default {
    namespaced: true,
    state : {
        project: [],
        project_detail: {},
       
        project_by_search:[],
       
        activity_location_count:[],

        submitLoading: false,
        submitError: false,

        detailLoading:false,
        detailError:false,


        activity: [],
        activity_detail: {},

        activityLoading:false,
        activityError:false,

        submitSuccess: false,

        location_employee:[],
        pending_drafts:[],
        approved_drafts:[],

        form: [],
        responses: [],
        activity_form_list:[],
        draft_details:{},

        activity_response_detail:{},
        activity_responses:[],

        draft_response_detail:{},

        activityFormLoading:false,
        activityFormError:false,

        objectives_by_project:[],
        objectiveLoading:false,
        objectiveError:false,

        ...store
    },
    mutations : {
        ...mutations,
        SET_PROJECT (state, project) {
            state.project = project;
        },
        SET_PROJECT_DETAIL (state, project) {
            state.project_detail = project;
        },

        SET_PROJECT_BY_SEARCH(state, project) {
            state.project_by_search = project;
        },

        SET_ACTIVITY_LOCATION_COUNT (state, location) {
            state.activity_location_count = location;
        },

        
    

        PROJECT_LOADING (state, loading) {
            state.submitLoading = loading;
        },

        PROJECT_ERROR (state, hasError) {
            state.submitError = hasError;
        },
        PROJECT_SUCCESS (state, success) {
            state.submitSuccess = success;
        },

        PROJECT_DETAIL_LOADING (state, loading) {
            state.detailLoading = loading;
        },
        PROJECT_DETIAL_ERROR (state, hasError) {
            state.detailError = hasError;
        },

        SET_ACTIVITY (state, activity) {
            state.activity = activity;
        },
        SET_ACTIVITY_DETAIL (state, activity) {
            state.activity_detail = activity;
        },


        ACTIVITY_LOADING (state, loading) {
            state.activityLoading = loading;
        },
        ACTIVITY_ERROR (state, hasError) {
            state.activityError = hasError;
        },

        



        //Activity Form
        SET_ACTIVITY_FORM (state, item) {
            state.form = item;
        },

        SET_ACTIVITY_RESPONSE_DETAILS(state, detail){
            state.activity_response_detail = detail
        },
        SET_ACTIVITY_RESPONSES(state, responses){
            state.activity_responses = responses
        },

        ACTIVITY_FORM_LOADING (state, loading) {
            state.activityFormLoading = loading;
        },
        ACTIVITY_FORM_ERROR (state, hasError) {
            state.activityFormError = hasError;
        },


        //Objective
        SET_OBJECTIVE_BY_PROJECT(state, objective){
            state.objectives_by_project = objective
        },

        OBJECTIVE_LOADING (state, loading) {
            state.objectiveLoading = loading;
        },
        OBJECTIVE_ERROR (state, hasError) {
            state.objectiveError = hasError;
        },

        //Draft
        SET_DRAFT_RESPONSE_DETAILS(state, detail){
            state.draft_response_detail = detail
        },

        //Location wise Employee
        SET_EMPLOYEE_BY_LOCATION(state, employee){
            state.location_employee = employee
        },

        //Pending Drafts
        SET_PENDING_DRAFTS (state, drafts) {
            state.pending_drafts = drafts;
        },
        SET_APPROVED_DRAFTS (state, draft) {
            state.approved_drafts = draft;
        },

        SET_DRAFT_DETAILS(state, draft_details) {
            state.draft_details = draft_details;
        },

     
    },

    actions: {

        async checkMyPassword({commit,dispatch}, payload) {
         
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let response = await axios.post(V1API.check_my_password, {password:payload.password}, config).then(result=>{
                    let data = result.data
                    return data
                });
                commit('PROJECT_SUCCESS', true);
               
                return response

            }catch (e) {
                
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },

        async addProject ({commit, dispatch}, payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };

            try{
                let data= new FormData()
                let payloadData = buildFormData(data, payload.project);

                if(payload.location !== null) {
                    payload.location.forEach((item) => data.append("location", item))
                }
                
                let response = await axios.post(V1API.create_project_bnps, payloadData, config).then(result=>{
                    // let data = decode(result.data)
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchProject')
                return response

            }catch (e) {
               
                commit('PROJECT_ERROR', true);
            }finally {
               
                commit('PROJECT_LOADING', false);
            }
        },


        async updateProject ({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  },
                  "Content-Type": "multipart/form-data"
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
               
                async () => {

                    let data= new FormData()
                    let payloadData = buildFormData(data, payload.project);

                    if(payload.location !== null) {
                        payload.location.forEach((item) => data.append("location", item))
                    }

                    response= await axios.patch(V1API.edit_project_bnps+payload.id,payloadData,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    // dispatch('fetchProject');

                }
            )
            return response
        },

        async deleteProject ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_bnps_project + payload, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchProject')
            
            return response
        },

        async fetchProject ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{

                if(!payload){
                    let result = await axios.get(V1API.get_project_bnps, config);
                    let response = result.data
                    commit('SET_PROJECT', response.data);
                }
                if(payload){
                    let result = await axios.get(V1API.get_project_bnps+'?project_status='+payload, config);
                    let response = result.data
                    commit('SET_PROJECT', response.data);
                }
                

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }

        
        },

        async fetchProjectDetails ({ commit }, payload) {
            commit('PROJECT_DETAIL_LOADING', true);
            commit('PROJECT_DETIAL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_bnps_project_detail + payload, config);
                let response = result.data

                commit('SET_PROJECT_DETAIL', response.data);

            } catch(error) {
                commit('PROJECT_DETIAL_ERROR', true);
            }
            finally {
                commit('PROJECT_DETAIL_LOADING', false);
            }
        },


        async fetchActivityLocationsCountByProject ({ commit }, payload) {
            commit('PROJECT_DETAIL_LOADING', true);
            commit('PROJECT_DETIAL_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_activity_location_count_by_project + payload, config);
                let response = result.data

                commit('SET_ACTIVITY_LOCATION_COUNT', response.data);

            } catch(error) {
                commit('PROJECT_DETIAL_ERROR', true);
            }
            finally {
                commit('PROJECT_DETAIL_LOADING', false);
            }
        },


        //Activity
        async fetchActivity ({ commit },payload) {
            commit('ACTIVITY_LOADING', true);
            commit('ACTIVITY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                if(payload.by_location){
                    let result = await axios.get(V1API.activity_list_by_project+payload.project_id + '?location_id=' + payload.location_id, config);
                    let items = result.data.data
                    commit('SET_ACTIVITY', items);
                }
                else if(payload.by_objective){
                    let result = await axios.get(V1API.activity_list_by_project+payload.project_id + '?objective_id=' + payload.objective_id, config);
                    let items = result.data.data
                    commit('SET_ACTIVITY', items);
                }
                else if(payload.both){
                    let result = await axios.get(V1API.activity_list_by_project+payload.project_id + '?objective_id=' + payload.objective_id +'&location_id='+payload.location_id, config);
                    let items = result.data.data
                    commit('SET_ACTIVITY', items);
                }
                else{
                    let result = await axios.get(V1API.activity_list_by_project+payload.project_id, config);
                    let items = result.data.data
                    commit('SET_ACTIVITY', items);
                }

            } catch(error) {
                commit('ACTIVITY_ERROR', true);
            }
            finally {
                commit('ACTIVITY_LOADING', false);
            }
           
        },

        async fetchActivityDetails ({ commit },payload) {
            commit('ACTIVITY_LOADING', true);
            commit('ACTIVITY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.activity_details+payload, config);
                let items = result.data.data

                commit('SET_ACTIVITY_DETAIL', items);

            } catch(error) {
                commit('ACTIVITY_ERROR', true);
            }
            finally {
                commit('ACTIVITY_LOADING', false);
            }
           
        },


        async addActivity ({commit, dispatch}, payload) {
            commit('ACTIVITY_LOADING', true);
            commit('ACTIVITY_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };

            try{
                let response = await axios.post(V1API.create_activity, payload.activity, config).then(result=>{
                    let data = result.data
                    return data
                });

                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchActivity',{project_id:payload.project_id} )
                return response

            }catch (e) {
                commit('ACTIVITY_ERROR', true);
            }finally {
                commit('ACTIVITY_LOADING', false);
            }
        },

        async updateActivity ({commit, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response = await axios.patch(V1API.edit_activity+ payload.id, payload.activity, config).then(result => {
                        let data = decode(result.data)
                        return data
                    })

                    dispatch ('fetchActivity',{project_id:payload.project_id})
                }
                
            )
            return response
        },

        async deleteActivity ({dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_activity + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })

            dispatch('fetchActivity',{project_id:payload.project_id});

            return response
        },


        //Activity Form
        async fetchForm ({ commit }, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.get_activity_form + payload, config);
                let response = result.data

                commit('SET_ACTIVITY_FORM', response.data);
                return response

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }
            finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },

        async addForm ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                
            };

            try{
                let response = await axios.post(V1API.create_activity_form + payload.location, payload.form, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchForm', payload.policy)
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },
        
        async submitActivityForm ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };


            try{


                payload.form_data.participants=JSON.stringify(payload.form_data.participants)
                payload.form_data.professions=JSON.stringify(payload.form_data.professions)
                payload.form_data.monthly_income=JSON.stringify(payload.form_data.monthly_income)
                payload.form_data.age_group=JSON.stringify(payload.form_data.age_group)
             

                let data= new FormData()
                let payloadData = buildFormData(data, payload.form_data);

                if(payload.attach !== null) {
                    payload.attach.forEach((item) => data.append("report_attach", item))
                }
                
                let response = await axios.post(V1API.activity_form_submit_create, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchForm', payload.policy)
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },
        async draftActivityForm ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };


            try{


                payload.form_data.participants=JSON.stringify(payload.form_data.participants)
                payload.form_data.professions=JSON.stringify(payload.form_data.professions)
                payload.form_data.monthly_income=JSON.stringify(payload.form_data.monthly_income)
                payload.form_data.age_group=JSON.stringify(payload.form_data.age_group)
             

                let data= new FormData()
                let payloadData = buildFormData(data, payload.form_data);

                if(payload.attach !== null) {
                    payload.attach.forEach((item) => data.append("report_attach", item))
                }
                if(payload.reported_to !== null) {
                    payload.reported_to.forEach((item) => data.append("reported_to", item),)
                }


                
                let response = await axios.post(V1API.draft_create, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                
                // dispatch('fetchForm',payload.formid).then(() => {
                //     dispatch('draftResponseDetails',{activty:payload.activity})
                // })
             
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },
        
        async draftResponseDetails ({ commit }, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.draft_view + '?activity='+payload.activity, config);
                let response = result.data

                commit('SET_DRAFT_RESPONSE_DETAILS', response.data);

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }
            finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },

        async editDraftActivity ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };


            try{


                payload.form_data.participants=payload.form_data.participants
                payload.form_data.professions=payload.form_data.professions
                payload.form_data.monthly_income=payload.form_data.monthly_income
                payload.form_data.age_group=payload.form_data.age_group
             

                let data= new FormData()
                let payloadData = buildFormData(data, payload.form_data);

                
                if(payload.attach !== null) {
                    payload.attach.forEach((item) => data.append("report_attach", item))
                }
                if(payload.reported_to !== null) {
                    payload.reported_to.forEach((item) => data.append("reported_to", item))
                }
                
                let response = await axios.patch(V1API.draft_edit + payload.draft_id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
               
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },

        async deleteDraftActivity ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.draft_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            // dispatch('fetchActivityFormRespones',{id:payload.form_id})
            
            return response
        },


        async editActivityFormByAdmin ({commit, dispatch}, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
                "Content-Type": "multipart/form-data"
            };


            try{


                payload.form_data.participants=JSON.stringify(payload.form_data.participants)
                payload.form_data.professions=JSON.stringify(payload.form_data.professions)
                payload.form_data.monthly_income=JSON.stringify(payload.form_data.monthly_income)
                payload.form_data.age_group=JSON.stringify(payload.form_data.age_group)
             

                let data= new FormData()
                let payloadData = buildFormData(data, payload.form_data);

                if(payload.attach !== null) {
                    payload.attach.forEach((item) => data.append("report_attach", item))
                }
                
                let response = await axios.patch(V1API.activity_form_submit_edit_by_admin + payload.form_id, payloadData, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                // dispatch ('fetchForm', payload.policy)
                return response

            }catch (e) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },


        async fetchActivityFormRespones ({ commit },payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                if(payload.filter){
                    let result = await axios.get(V1API.get_activity_form_responses+payload.id + '?start_date='+payload.start_date+'&end_date='+payload.end_date, config);
                    let response = result.data
                    commit('SET_ACTIVITY_RESPONSES', response.data);  
                }else{
                    let result = await axios.get(V1API.get_activity_form_responses+payload.id, config);
                    let response = result.data
                    commit('SET_ACTIVITY_RESPONSES', response.data);  
                }

             

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }

        
        },

        async fetchActivityResponseDetails ({ commit }, payload) {
            commit('ACTIVITY_FORM_LOADING', true);
            commit('ACTIVITY_FORM_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.activity_form_response_details + payload, config);
                let response = result.data

                commit('SET_ACTIVITY_RESPONSE_DETAILS', response.data);

            } catch(error) {
                commit('ACTIVITY_FORM_ERROR', true);
            }
            finally {
                commit('ACTIVITY_FORM_LOADING', false);
            }
        },


        async deleteActivityResponse ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.delete_activity_form_responses + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            dispatch('fetchActivityFormRespones',{id:payload.form_id})
            
            return response
        },


        //Objectives
        async addObjective({commit, dispatch}, payload) {
            commit('OBJECTIVE_LOADING', true);
            commit('OBJECTIVE_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                },
            };
            try{
                let response = await axios.post(V1API.objective_create, payload.objective, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('PROJECT_SUCCESS', true);
                dispatch ('fetchObjectiveByProject',{projectId:payload.project_id})
                return response

            }catch (e) {
                commit('OBJECTIVE_ERROR', true);
            }finally {
                commit('OBJECTIVE_LOADING', false);
            }
        },

        async fetchObjectiveByProject ({ commit },payload) {
            commit('OBJECTIVE_LOADING', true);
            commit('OBJECTIVE_ERROR', false);
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.objective_list_by_project + payload.projectId, config);
                const results = result.data.data
                commit('SET_OBJECTIVE_BY_PROJECT',results);

            } catch(error) {
                commit('OBJECTIVE_ERROR', true);
            }
            commit('OBJECTIVE_LOADING', false);
        },

        async updateObjective({commit, state, dispatch}, payload) {
            let response='';
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                  }
            };
            await performNetworkCall(
                commit,
                keyList.smsUpdate,
                async () => {
                    response= await axios.patch(V1API.objective_edit+payload.id,payload.objective,config).then(result=>{
                        let data=decode(result.data)
                        return data
                    })

                    dispatch('fetchObjectiveByProject',{projectId:payload.project_id});
                }
            )
            return response
        },

        async deleteObjective ({commit, dispatch}, payload) {
            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            let response = await axios.delete(V1API.objective_delete + payload.delete, config).then(result=>{
                let data = result.data
                return data
            })
            
            dispatch('fetchObjectiveByProject',{projectId:payload.project_id});
            return response
        },


        //Location wise Employee
        async fetchEmployeeListByLocation ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.location_wise_employee +'?location='+payload, config);
                let response = result.data.data

                commit('SET_EMPLOYEE_BY_LOCATION', response);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {

                commit('PROJECT_LOADING', false);
            }
        },


        //Pending Drafts List

        async fetchPendingDraftsList ({ commit }) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.pending_drafts_list, config);
                let response = result.data.data

                commit('SET_PENDING_DRAFTS', response);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {

                commit('PROJECT_LOADING', false);
            }
        },
        async fetchApprovedDraftsList ({ commit }) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.approved_drafts_list, config);
                let response = result.data.data

                commit('SET_APPROVED_DRAFTS', response);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {

                commit('PROJECT_LOADING', false);
            }
        },

        async fetchDraftDetails ({ commit },payload) {
            commit('PROJECT_LOADING', true);
            commit('PROJECT_ERROR', false);

            let config = {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try{
                let result = await axios.get(V1API.draft_details + payload, config);
                let response = result.data.data

                commit('SET_DRAFT_DETAILS', response);

            } catch(error) {
                commit('PROJECT_ERROR', true);
            }
            finally {

                commit('PROJECT_LOADING', false);
            }
        },


        async updateDraftStatus ({commit, dispatch}, payload) {
            let config = {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("access_token"),
                }
            };

            try {

                let response = await axios.get(V1API.update_darft_status+"/" + payload.id+"/"+ payload.status, config).then(result=>{
                    let data = result.data
                    return data

                });

                commit('SUBMIT_SUCCESS', true);
                if(payload.from_pending){
                    dispatch ('fetchPendingDraftsList')
                }
                else if(payload.from_approved){
                    dispatch ('fetchApprovedDraftsList')
                }
                else{

                }
               
                
                return response

            }catch (e) {
                commit('PROJECT_ERROR', true);
            }finally {
                commit('PROJECT_LOADING', false);
            }
        },

        
        

       

    },

    getters: {}
}
