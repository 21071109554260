<template>
<div>
    <Breadcrumbs main="BNPS" submain="Activity" title="Activity Form Responses" />
    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">

                    <div class="card-body">

                        <div class="d-flex flex-column flex-sm-row" style="gap: 10px; ">
                            <div class="mr-3 mr-md-4 field-width">
                                <b-input-group class="datatable-btn w-100">
                                    <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                    <b-input-group-append>
                                        <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </div>
                            <div class="d-flex mt-sm-0">
                                <div class="d-flex flex-column flex-lg-row" style="gap:5px">

                                    <datepicker placeholder="Start Date" input-class="datepicker-here form-control digits" v-model="sort.start_date"></datepicker>
                                    <datepicker placeholder="End Date" input-class="datepicker-here form-control digits" v-model="sort.end_date"></datepicker>

                                    <div class="d-flex align-items-center">
                                        <b-button @click="datewiseData()">Filter</b-button>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 flex align-items-end">
                                <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select w-100">
                                    <b-form-select v-model="perPage" :options="pageOptions" style="width: 100%;"></b-form-select>
                                </b-form-group>
                            </div>
                        </div>

                       
                        <div v-if="!loading && !error">
                            <div class="table-responsive datatable-vue">
                                <b-table show-empty stacked="md" :items="response_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                    <template #cell(index)="data">
                                        {{ (currentPage - 1) * perPage + data.index + 1 }}
                                    </template>

                                    <template #cell(id)="data">
                                        <div v-if="data.item.activity.activity" class="d-flex flex-column">
                                            <router-link :to="`/internal-activity/activity/response-details/${data.item.id}`" class="link-style">
                                                {{ data.item.activity.activity.activity.title }} - {{ data.item.activity.activity.location.name }}
                                            </router-link>
                                            <p>{{ updateTime(data.item.created_at) }}</p>
                                        </div>
                                    </template>

                                    <template #cell(action)="data">
                                        <span style="display: flex; gap: 12px;">
                                            <router-link :to="`/internal-activity/activity/response-edit/${$route.params.id}/${data.item.id}`">
                                                <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer; padding-top: 2px;" v-b-tooltip.hover title="Edit"></feather>
                                            </router-link>

                                            <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover v-b-modal.modal-center @click="deleteResponse(data.item.id)" title="Delete"></feather>

                                        </span>
                                    </template>
                                </b-table>
                            </div>
                        </div>
                        <div v-else>
                            <loader />
                        </div>

                        <b-col md="6" class="my-1 p-0">
                            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                        </b-col>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Container-fluid Ends-->

    <!-- Delete Modal -->
    <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
        <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this response?</div>
    </b-modal>
</div>
</template>

<script>
import {
    mapState
} from 'vuex';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import loader from '../../loader/loader.vue'
import Datepicker from 'vuejs-datepicker';
import moment from "moment";
export default {
    components: {
        loader,
        Datepicker
    },
    data() {
        return {
            sort: {
                start_date: null,
                end_date: null,
            },
            tablefields: [{
                    key: 'index',
                    label: '#'
                },
                {
                    key: 'id',
                    label: 'Responses'
                },

                {
                    key: 'action',
                    label: 'Action',
                    sortable: false
                }
            ],

            delete_value: null,
            udate_value: null,

            filter: null,

            totalRows: 1,
            currentPage: 1,
            perPage: 10,
            pageOptions: [5, 10, 15],

            temp_quotation: {}
        };
    },

    computed: {
        ...mapState({
            response_list: state => state.bnps_project.activity_responses,
            loading: state => state.bnps_project.activityFormLoading,
            error: state => state.bnps_project.activityFormError,
        }),
        sortOptions() {
            return this.tablefields
                .filter(f => f.sortable)
                .map(f => {
                    return {
                        text: f.label,
                        value: f.key
                    };
                });
        }
    },

    mounted() {

        this.$store.dispatch("bnps_project/fetchActivityFormRespones", {
            id: this.$route.params.id
        }).then(() => {
            this.items = this.response_list;
            this.totalRows = this.items.length;

        });

    },
    watch: {
        response_list(newValue, oldValue) {

        },
        items(newValue, oldValue) {

        },
    },

    methods: {
        datewiseData() {
            if (this.sort.start_date !== null) {
                this.sort.start_date = moment(String(this.sort.start_date)).format("YYYY-MM-DD");
            }
            if (this.sort.end_date !== null) {
                this.sort.end_date = moment(String(this.sort.end_date)).format("YYYY-MM-DD");
            }

            console.log(this.sort.end_date)

            this.$store.dispatch("bnps_project/fetchActivityFormRespones", {
                id: this.$route.params.id,
                filter: true,
                start_date: this.sort.start_date,
                end_date: this.sort.end_date
            }).then(() => {
                this.items = this.response_list;
                this.totalRows = this.items.length;

            });

        },
        updateTime(timestamp) {
            const date = new Date(timestamp);
            const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${
            (date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()}`;

            // Format time as HH:MM:SS AM/PM
            let hours = date.getHours();
            const minutes = date.getMinutes();
            const seconds = date.getSeconds();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12;

            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')} ${ampm}`;

            return `${formattedDate } ${formattedTime}`;
        },
        onFiltered(filteredItems) {
            this.items = this.response_list
            this.totalRows = this.items.length;
        },

        deleteResponse(id) {
            this.delete_value = id
        },
        handleDeleteOk() {
            this.$store.dispatch("bnps_project/deleteActivityResponse", {
                delete: this.delete_value,
                form_id: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    this.delete_value = null

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        },

    }
};
</script>
