<template>
    <div>
        <Breadcrumbs main="BNPS" :submain="project.title" title="Details" />
        <div class="container-fluid jkanban-container">
            <!-- Project Details -->
            <div class="row">
                <div class="col-12" v-if="!loading && !error">
                    <div class="card">
                        <div class="card-header title-button-container">
                            <div style="display: flex; flex-direction: column; gap: 10px;">
                                <div style="display: flex; flex-direction: row; gap: 10px;">
                                    <h5>{{ project.title }}</h5>
                                    <!-- <router-link :to="{ path: '/internal-activity/edit-project/' + project.id }">
                                        <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer; padding-top: 2px;" v-b-tooltip.hover title="Edit"></feather>
                                    </router-link> -->
                                </div>
                            </div>
    
                        </div>
    
                        <div class="card-body">
                            <div v-html="project.details"></div>
    
                            <div>
                                <div class="row">
                                    <div class="mt-4 col col-lg-8">
                                   
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                            <div style="width: 140px;">
                                                <span>Priority</span>
                                            </div>
                                            <span v-if="project.priority == 'MEDIUM'" class="badge" :class="'badge-primary'" v-b-modal.modal-prevent-closing-status v-b-tooltip.hover title="Change Priority">{{ project.priority }}</span>
                                            <span v-if="project.priority == 'LOW'" class="badge" :class="'badge-danger'" v-b-modal.modal-prevent-closing-status title="Change Priority">{{ project.priority }}</span>
                                            <span v-if="project.priority == 'HIGH'" class="badge" :class="'badge-success'" v-b-modal.modal-prevent-closing-status title="Change Priority">{{ project.priority }}</span>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                            <div style="width: 140px;">
                                                <span>Start Date</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.start_date }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px;">
                                            <div style="width: 140px;">
                                                <span>Deadline</span>
                                            </div>
                                            <div style="font-size: medium;">{{ project.deadline }}</div>
                                        </div>
    
                                        <div style="display: flex; flex-direction: row; align-items: center; margin-top: 4px; margin-bottom: 4px;">
                                            <div style="width: 140px;">
                                                <span>Location</span>
                                            </div>
                                            <div class="d-flex flex-column flex-lg-row">
                                                <div v-for="(loc, index) in project.location" :key="index" style="">
                                                    <span style="font-size: small; text-align: center; max-width: fit-content; margin-right: 4px; margin-bottom: 4px;" class="badge badge-primary">{{ loc.name }}</span>
                                                </div>
                                            </div>
    
                                        </div>
    
                                        <!-- <div style="display: flex; flex-direction: column; align-items: start; margin-top: 4px; margin-bottom: 4px;">
                                                <div style="width: 120px;">
                                                    <span>Members</span>
                                                </div>
                                                <div style="display: flex; align-items: center; gap: 10px;">
                                                    <ul class="mt-2" style="display: flex; gap: 8px;" v-for="(team, index) in project.teammates" :key="index">
                                                        <li class="d-inline-block" v-if="team.user_id.additional_info">
                                                            <img class="img-50 rounded-circle" :src="host + team.user_id.additional_info.photo" alt="" v-b-tooltip.hover :title="team.user_name">
                                                        </li>
    
                                                        <li v-else class="d-inline-block" v-b-tooltip.hover :title="team.user_name"><img class="img-50 rounded-circle" src="../../../assets/images/user/3.jpg" alt="" data-original-title=""></li>
                                                    </ul>
    
                                                    <button style="margin-top: 8px; background-color: #ff595d; color: white; border-color: #ff595d; border-radius: 0.25rem;" v-b-modal.modal-1-assign>
                                                        Add New
                                                    </button>
    
                                                </div>
                                            </div> -->
                                    </div>
    
                                </div>
    
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12" v-else>
                    <px-card>
                        <loader />
                    </px-card>
                </div>
            </div>
    
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-header">
                            <h5>Activities</h5>
                        </div>
                        <div class="card-body">
                            <b-row>
                                <b-col xl="6">
    
                                    <b-input-group class="datatable-btn">
                                        <b-form-input v-model="filter" placeholder="Type to Search"></b-form-input>
                                        <b-input-group-append>
                                            <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                                        </b-input-group-append>
                                    </b-input-group>
    
                                </b-col>
    
                                <b-col xl="6">
                                    <b-form-group label-cols="2" label="Per page" class="mb-0 datatable-select">
                                        <b-form-select v-model="perPage" :options="pageOptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
    
                            <div v-if="!activity_Loading && !activity_Error">
                                <div class="table-responsive datatable-vue">
                                    <b-table show-empty stacked="md" :items="activity_list" :fields="tablefields" :filter="filter" :current-page="currentPage" :per-page="perPage" @filtered="onFiltered">
                                        <template #cell(index)="data">
                                            {{ (currentPage - 1) * perPage + data.index + 1 }}
                                        </template>
                                        <template #cell(title)="data">
                                            <div style="min-width: 200px;" v-if="data.item.title">
                                                {{data.item.title }}
                                            </div>
                                            <div class="d-flex flex-column mt-2" style="min-width: 200px;">
                                                <div v-if="data.item.start_date" class="d-flex">
                                                    <span class="mr-4" style="min-width: 80px;">Start Date:</span>
                                                    <span>{{ data.item.start_date }}</span>
                                                </div>
                                                <div v-if="data.item.end_date" class="d-flex">
                                                    <span class="mr-4" style="min-width: 80px;">End Date:</span>
                                                    <span>{{ data.item.end_date }}</span>
                                                </div>
                                            </div>
                                        </template>
    
                                        <template #cell(status)="data">
                                            <span>
                                                <small v-if="data.item.status == 'PENDING'"><span style="font-size: medium;">
                                                        <b-badge pill variant="warning">Pending</b-badge>
                                                    </span></small>
                                                <small v-if="data.item.status == 'IN-PROGRESS'"><span style="font-size: medium;">
                                                        <b-badge pill variant="primary">In Progress</b-badge>
                                                    </span></small>
                                                <small v-if="data.item.status == 'HOLD'"><span style="font-size: medium;">
                                                        <b-badge pill variant="danger">Hold</b-badge>
                                                    </span></small>
                                                <small v-if="data.item.status == 'COMPLETED'"><span style="font-size: medium;">
                                                        <b-badge pill variant="success">Completed</b-badge>
                                                    </span></small>
                                            </span>
                                        </template>
                                        <template #cell(priority)="data">
                                            <span>
                                                <small v-if="data.item.priority == 1"><span style="font-size: medium;">
                                                        <b-badge pill variant="danger">Low</b-badge>
                                                    </span></small>
                                                <small v-if="data.item.priority == 2"><span style="font-size: medium;">
                                                        <b-badge pill variant="warning">Medium</b-badge>
                                                    </span></small>
                                                <small v-if="data.item.priority == 3"><span style="font-size: medium;">
                                                        <b-badge pill variant="success">High</b-badge>
                                                    </span></small>
                                            </span>
                                        </template>
    
                                        <!-- <template #cell(date)="data">
                                            
                                        </template> -->
    
                                        <!-- <template #cell(action)="data">
                                            <span style="display: flex; gap: 12px;">
                                                <feather type="delete" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover name="Delete" v-b-modal.modal-center @click="deleteItems(data.item.id)"></feather>
                                                <div @click="editItem(data.item)">
                                                    <feather type="edit" size="20px" style="color: #568ae3; cursor: pointer;" v-b-tooltip.hover name="Edit" v-b-modal.modal-prevent-closing-edit></feather>
                                                </div>
                                            </span>
                                        </template> -->
    
                                        <template #cell(location)="data">
                                            <div v-for="item in data.item.locations" :key="item.id" style="min-width: 210px;">
                                                <div class="d-flex flex-row" style="gap: 15px;">
                                                    <div>
                                                        <span>{{ item.location.name }} - </span>
                                                        <span>{{ item.quantity }}</span>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </template>
    
    
                                        <template #cell(form)="data">
                                            <div v-for="item in data.item.locations" :key="item.id" style="min-width: 210px;">
                                                <div class="d-flex flex-row" style="gap: 15px;">
                                                
                                                    <div>
                                                
                                                        <span v-if="item.form_generated == true">
                                                            <router-link :to="{ name: 'ViewActivityForm', params: { id: item.id }, query: { activity: item.activity.id, location: item.location.id} }">
                                                                <feather type="eye" size="22px" style="color: #ff595d; cursor: pointer;" v-b-tooltip.hover title="View"></feather>
                                                            </router-link>
                                                        </span>
                                                        <span v-else>
                                                            N/A
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        
    
                                     
                                    </b-table>
                                </div>
                            </div>
    
                            <div v-else>
                                <loader />
                            </div>
    
                            <b-col md="6" class="my-1 p-0">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
                            </b-col>
                        </div>
                    </div>
    
                </div>
            </div>
    
        </div>
        <!-- Activity Create Modal -->
        <b-modal id="modal-prevent-closing" size="xl" ref="modal" title="Add activity" @show="resetModal" @hidden="resetModal" @ok="handleOk">
            <form ref="form" @submit.stop.prevent="handleSubmit">
    
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" v-model="activity.title" class="form-control" placeholder="Enter title" style="height: 43px;" />
                        </div>
                    </div>
    
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Priority</label>
                            <select style="height: 44px;" class="form-control digits" v-model="activity.priority">
                                <option v-for="(type, index) in priorities" :key="index" :value="type.value">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Status</label>
                            <select style="height: 44px;" class="form-control digits" v-model="activity.status">
                                <option v-for="(type, index) in taskStatus" :key="index" :value="type.value">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Start date</label>
                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="activity.start_date" style="height: 43px;"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>End Date</label>
                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="activity.end_date" style="height: 43px;"></datepicker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Description</label>
                            <b-form-textarea v-model="activity.description" rows="3" max-rows="8" placeholder="Enter your reason"></b-form-textarea>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="mt-4" style="display: flex; gap: 16px;">
                            <div style="width: 40%;">
                                <h6>Locations</h6>
                            </div>
    
                            <div style="width: 40%;">
                                <h6>Quantity</h6>
                            </div>
    
                            <div style="width: 20%;">
                                <h6>Action</h6>
                            </div>
                        </div>
    
                        <div style="display: flex; gap: 4px; margin-bottom: 12px;" v-for="(fo, index) in activity.locations" :key="index">
    
                            <div style="width: 40%;">
                                <div>
                                    <b-form-select v-model="fo.location_id">
                                        <b-form-select-option v-for="option in location_list" :value="option.id" :key="option.id">{{ option.name }}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div style="width: 40%;">
                                <div class="w-fullbg-white rounded-sm">
                                    <b-form-input v-model="fo.quantity" type="text" placeholder="Enter quantity"></b-form-input>
                                </div>
                            </div>
    
                            <div style="width: 20%; display: flex; align-items: center;">
                                <!-- Add Svg Icon -->
                                <svg @click="addField(input, activity.locations)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                </svg>
    
                                <!-- Remove Svg Icon -->
                                <svg v-show="activity.locations.length > 1" @click="removeField(index, activity.locations)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
    
            </form>
        </b-modal>
    
        <!--Activity Edit Modal -->
        <b-modal id="modal-prevent-closing-edit" size="xl" ref="modal" title="Edit Activity" @show="resetEditModal" @hidden="resetEditModal" @ok="handleEditOk">
            <form ref="form" @submit.stop.prevent="handleEditSubmit">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>Title</label>
                            <input type="text" v-model="edit_activity.title" class="form-control" placeholder="Enter title" style="height: 43px;" />
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Priority</label>
                            <select style="height: 44px;" class="form-control digits" v-model="edit_activity.priority">
                                <option v-for="(type, index) in priorities" :key="index" :value="type.value">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
    
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Status</label>
                            <select style="height: 44px;" class="form-control digits" v-model="edit_activity.status">
                                <option v-for="(type, index) in taskStatus" :key="index" :value="type.value">{{ type.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>Start date</label>
                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="edit_activity.start_date" style="height: 43px;"></datepicker>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>End Date</label>
                            <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="edit_activity.end_date" style="height: 43px;"></datepicker>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label>Description</label>
                            <b-form-textarea v-model="edit_activity.description" rows="3" max-rows="8" placeholder="Enter your reason"></b-form-textarea>
                        </div>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col-sm-12">
                        <div class="mt-4" style="display: flex; gap: 16px;">
                            <div style="width: 40%;">
                                <h6>Locations</h6>
                            </div>
    
                            <div style="width: 40%;">
                                <h6>Quantity</h6>
                            </div>
    
                            <div style="width: 20%;">
                                <h6>Action</h6>
                            </div>
                        </div>
    
                        <div style="display: flex; gap: 4px; margin-bottom: 12px;" v-for="(fo, index) in edit_activity.locations" :key="index">
    
                            <div style="width: 40%;">
                                <div>
                                    <b-form-select v-model="fo.location_id">
                                        <b-form-select-option v-for="option in location_list" :value="option.id" :key="option.id">{{ option.name }}</b-form-select-option>
                                    </b-form-select>
                                </div>
                            </div>
                            <div style="width: 40%;">
                                <div class="w-fullbg-white rounded-sm">
                                    <b-form-input v-model="fo.quantity" type="text" placeholder="Enter quantity"></b-form-input>
                                </div>
                            </div>
    
                            <div style="width: 20%; display: flex; align-items: center;">
                                <!-- Add Svg Icon -->
                                <svg @click="addField(input, edit_activity.locations)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                </svg>
    
                                <!-- Remove Svg Icon -->
                                <svg v-show="edit_activity.locations.length > 1" @click="removeField(index, edit_activity.locations)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
    
            </form>
        </b-modal>
    
        <!-- Activity delete Modal -->
        <b-modal id="modal-center" centered hide-header @ok="handleDeleteOk">
            <div class="mt-4 mb-4" style="font-size: large;">Are you sure you want to delete this activity?</div>
        </b-modal>
    </div>
    </template>
    
    <script>
    import {
        mapState
    } from 'vuex';
    import Multiselect from 'vue-multiselect';
    import Swal from 'sweetalert2/dist/sweetalert2.js'
    import 'sweetalert2/src/sweetalert2.scss'
    import random from "vue-rate-it/glyphs/random";
    import Datepicker from 'vuejs-datepicker';
    import vue2Dropzone from 'vue2-dropzone';
    import moment from "moment";
    import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
    import {
        myHost
    } from "../../../_helpers/host";
    import loader from '../../loader/loader.vue';
    
    export default {
        components: {
            Multiselect,
            vueDropzone: vue2Dropzone,
            Datepicker,
            loader,
    
        },
    
        data() {
            return {
                editor: ClassicEditor,
                project: {},
    
                tablefields: [{
                        key: 'index',
                        label: '#'
                    },
                    {
                        key: 'title',
                        label: 'Title',
                        sortable: true
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        sortable: true
                    },
                    {
                        key: 'priority',
                        label: 'Priority',
                        sortable: true
                    },
    
                    {
                        key: 'location',
                        label: 'Location',
                        sortable: true
                    },
                    {
                        key: 'form',
                        label: 'Form',
                        sortable: true
                    },
                    // {
                    //     key: 'date',
                    //     label: 'Date',
                    //     sortable: true
                    // },
                    // {
                    //     key: 'form_generated',
                    //     label: 'Form',
                    //     sortable: true
                    // },
    
                    // {
                    //     key: 'action',
                    //     label: 'Action',
                    //     sortable: false
                    // }
                ],
    
                activity: {
                    title: '',
                    priority: '',
                    status: '',
                    start_date: '',
                    end_date: '',
                    description: '',
                    project: null,
                    locations: [{
                        location_id: null,
                        quantity: null,
                    }]
                },
    
                edit_activity: {
                    id: null,
                    title: '',
                    priority: '',
                    status: '',
                    start_date: '',
                    end_date: '',
                    description: '',
                    project: null,
                    locations: [{
                        location_id: null,
                        quantity: null,
                    }]
                },
                priorities: [{
                        name: 'LOW',
                        value: 1,
                    },
                    {
                        name: 'MEDIUM',
                        value: 2,
                    },
                    {
                        name: 'HIGH',
                        value: 3,
                    }
                ],
    
                taskStatus: [{
                        name: 'Pending',
                        value: 'PENDING',
                    },
                    {
                        name: 'In Progress',
                        value: 'IN-PROGRESS',
                    },
                    {
                        name: 'Hold',
                        value: 'HOLD',
                    },
                    {
                        name: 'Completed',
                        value: 'COMPLETED',
                    }
                ],
                delete_value: null,
    
                filter: null,
    
                totalRows: 1,
                currentPage: 1,
                perPage: 10,
                pageOptions: [5, 10, 15]
    
            };
        },
    
        created() {
            this.$store.dispatch("bnps_project/fetchProjectDetails", this.$route.params.id)
    
        },
        mounted() {
            this.$store.dispatch("bnps_project/fetchActivity", {project_id:this.$route.params.id}).then(() => {
                this.items = this.activity_list;
                this.totalRows = this.items.length;
            });
            this.$store.dispatch("bnps_project/fetchProject")
            this.$store.dispatch("location/fetchLocation")
        },
    
        computed: {
            ...mapState({
    
                project_detail: state => state.bnps_project.project_detail,
                project_list: state => state.bnps_project.project,
                location_list: state => state.location.location,
                loading: state => state.bnps_project.detailLoading,
                error: state => state.bnps_project.detailError,
    
                activity_list: state => state.bnps_project.activity,
                activity_Loading: state => state.bnps_project.activityLoading,
                activity_Error: state => state.bnps_project.activityError
    
            }),
    
            sortOptions() {
                // Create an options list from our fields
                return this.tablefields
                    .filter(f => f.sortable)
                    .map(f => {
                        return {
                            text: f.label,
                            value: f.key
                        };
                    });
            }
    
        },
    
        watch: {
    
            project_detail(oldValue, newValue) {
                this.project = this.project_detail;
    
            },
    
        },
    
        methods: {
            addField(value, fieldType) {
                fieldType.push({
                    location_id: null,
                    quantity: null,
                });
            },
    
            removeField(index, fieldType) {
                fieldType.splice(index, 1);
            },
            truncateText(text, maxLength) {
                if (text.length > maxLength) {
                    return text.substring(0, maxLength) + '...';
                } else {
                    return text;
                }
            },
    
            convertUrls(text) {
                if (text === null) {
                    return '';
                }
                const urlPattern = /(https?:\/\/[^\s<]+)/g;
                return text.replace(urlPattern, (url) => {
                    return `<a href="${url}" target="_blank" class="text-primary">${url}</a>`;
                });
            },
    
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length;
                this.currentPage = 1;
            },
    
            resetModal() {
                this.activity.title = ''
                this.activity.priority = ''
                this.activity.status = ''
                this.activity.start_date = ''
                this.activity.end_date = ''
                this.activity.description = ''
                this.activity.project = null
                this.activity.locations = [{
                    location_id: null,
                    quantity: null,
                }]
    
            },
    
            handleOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleSubmit()
            },
    
            handleSubmit() {
                if (this.activity.start_date !== null) {
                    this.activity.start_date = moment(String(this.activity.start_date)).format("YYYY-MM-DD");
                }
                if (this.activity.end_date !== null) {
                    this.activity.end_date = moment(String(this.activity.end_date)).format("YYYY-MM-DD");
                }
                this.activity.project = this.$route.params.id
                this.$store.dispatch("bnps_project/addActivity", {
                    activity: this.activity,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.activity.title = ''
                        this.activity.priority = ''
                        this.activity.status = ''
                        this.activity.start_date = ''
                        this.activity.end_date = ''
                        this.activity.description = ''
                        this.activity.project = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing')
                })
            },
    
            editItem(type) {
                this.edit_activity.id = type.id
                this.edit_activity.title = type.title ? type.title : null
                this.edit_activity.priority = type.priority ? type.priority : null
                this.edit_activity.status = type.status ? type.status : null
                this.edit_activity.start_date = type.start_date ? type.start_date : null
                this.edit_activity.end_date = type.end_date ? type.end_date : null
                this.edit_activity.description = type.description ? type.description : null
    
                if (type.locations.length > 0 && Array.isArray(type.locations)) {
                    this.edit_activity.locations = type.locations.map(location => ({
                        location_id: location.location.id,
                        quantity: location.quantity,
                    }));
                } else {
                    this.edit_activity.locations = [{
                        location_id: null,
                        quantity: null,
                    }];
                }
    
            },
    
            resetEditModal() {
    
                this.edit_activity.title = ''
                this.edit_activity.priority = ''
                this.edit_activity.status = ''
                this.edit_activity.start_date = ''
                this.edit_activity.end_date = ''
                this.edit_activity.description = ''
                this.edit_activity.project = null
                this.edit_activity.locations = [{
                    location_id: null,
                    quantity: null,
                }]
            },
    
            handleEditOk(bvModalEvent) {
                bvModalEvent.preventDefault()
                this.handleEditSubmit()
            },
    
            handleEditSubmit() {
                if (this.edit_activity.start_date !== null) {
                    this.edit_activity.start_date = moment(String(this.edit_activity.start_date)).format("YYYY-MM-DD");
                }
                if (this.edit_activity.end_date !== null) {
                    this.edit_activity.end_date = moment(String(this.edit_activity.end_date)).format("YYYY-MM-DD");
                }
                this.edit_activity.project = this.$route.params.id
                this.$store.dispatch("bnps_project/updateActivity", {
                    activity: this.edit_activity,
                    id: this.edit_activity.id,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.edit_activity.title = ''
                        this.edit_activity.priority = ''
                        this.edit_activity.status = ''
                        this.edit_activity.start_date = ''
                        this.edit_activity.end_date = ''
                        this.edit_activity.description = ''
                        this.edit_activity.project = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-prevent-closing-edit')
                })
            },
    
            deleteItems(id) {
                this.delete_value = id
            },
    
            handleDeleteOk() {
                this.$store.dispatch("bnps_project/deleteActivity", {
                    delete: this.delete_value,
                    project_id: this.$route.params.id
                }).then(response => {
                    if (response.code == 200) {
                        Swal.fire({
                            title: "Success!",
                            text: response.response,
                            icon: "success"
                        });
    
                        this.delete_value = null
    
                    } else {
                        Swal.fire({
                            title: "Error!",
                            text: response.response,
                            icon: "error"
                        });
                    }
                })
            }
    
        }
    };
    </script>
    
    <style src="vue-multiselect/dist/vue-multiselect.min.css"></style><style scoped>
    .title-button-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .text-style {
        margin-bottom: 2px;
    }
    
    .upper-button-container {
        display: flex;
        position: relative;
        transition: 0.6s;
        cursor: pointer;
        width: fit-content;
        gap: 12px;
        cursor: pointer;
    }
    
    .iconStyle {
        width: 36px;
        height: 36px;
        background-color: #222131;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        font-size: 20px;
        transition: all 0.3s ease-in-out;
        position: relative;
        z-index: 1;
    }
    
    .description-container {
        position: absolute;
        width: 0;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: width 1s ease, opacity 0.6s ease, visibility 0s;
        z-index: 99;
        display: flex;
        align-items: center;
        background-color: #222131;
        border-radius: 24px;
        color: white;
    }
    
    .description-inner {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;
    }
    
    .iconStyle:hover+.description-container,
    .description-container:hover {
        width: 100%;
        opacity: 1;
        visibility: visible;
    }
    
    .iconStyle:hover+.description-container {
        transition-delay: 0.2s;
    }
    
    .detail-container {
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    
    .button-bill {
        display: flex;
        gap: 10px;
    }
    
    .dropdown {
        position: relative;
    }
    
    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        padding: 10px;
        z-index: 99;
        right: 2px;
    
    }
    
    .dropdown:hover .dropdown-content {
        display: flex;
        flex-direction: column;
        gap: 2px;
        font-weight: 300;
    }
    
    .task-board-full {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 10px;
    }
    
    .task-board-sub-task {
        width: 60%;
    }
    
    .task-board-comment-width {
        width: 40%;
    }
    
    .task-board-task-scroll {
        position: relative;
        height: 84vh;
    }
    
    .task-board-scroll {
        position: relative;
        height: 84vh;
    }
    
    .task-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    
    .task-board-comments {
        display: flex;
        flex-direction: column;
        /* gap: 15px; */
        height: 93%;
        overflow-y: auto;
    }
    
    .task-board-comment-box {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
    
    .badge-style {
        display: flex;
        justify-content: space-between;
        align-items: center;
        justify-items: center;
        background-color: #2c323f;
        padding: 0.375rem 1.75rem;
        color: white;
    
    }
    
    .badge-style:hover {
        background-color: #ff595d;
    }
    
    .chatbox {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 0px 10px;
    }
    
    .chat {
        display: flex;
        gap: 10px;
        justify-content: center;
        align-items: center;
        word-break: break-all;
    }
    
    .avatar img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
        border: solid 2px white;
    }
    
    .message {
        flex-grow: 1;
    }
    
    .msg {
        background: #fff0f0;
        width: 100%;
        /* Ensures message takes full width */
        padding: 10px;
        border-radius: 10px;
        position: relative;
        font-size: 13px;
        word-break: break-all;
    }
    
    /* height */
    ::-webkit-scrollbar {
        height: 6px;
        width: 5px;
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 10px;
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    
    @media screen and (max-width: 1279px) {
        .detail-container {
            display: flex;
            flex-direction: column;
            gap: 25px;
        }
    }
    
    /* @media screen and (max-width: 991px) {
            .task-board-full {
                display: flex;
                flex-direction: column;
                width: 100%;
                gap: 40px;
            }
    
            .task-board-sub-task {
                width: 100%;
            }
    
            .task-board-comment-width {
                width: 100%;
            }
    
            .task-board-task-scroll {
                position: relative;
                height: 100%;
            }
    
            .task-board-scroll {
                position: relative;
                height: 70vh;
            }
    
            .task-container {
                display: flex;
                flex-direction: column;
                justify-content: start;
                align-items: start;
                gap: 20px;
            }
    
            .task-board-comments {
                height: 88%;
            }
        } */
    
    @media screen and (max-width: 767px) {
        .title-button-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-between;
            align-items: start;
        }
    
        .text-style {
            margin-bottom: 5px;
        }
    }
    
    .img-50 {
        width: 50px !important;
        height: 50px !important;
    }
    
    .img-30 {
        width: 30px !important;
        height: 30px !important;
    }
    
    .img-20 {
        width: 20px !important;
        height: 20px !important;
    }
    
    .ck-editor-container {
        overflow: hidden !important;
        word-wrap: break-word;
    }
    
    .ck-editor__editable {
        word-break: break-all;
    }
    </style>
    