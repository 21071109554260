<template>
<div>
    <Breadcrumbs main="BNPS" submain="Create" title="Activity Form" />

    <!-- Container-fluid starts-->
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-header">
                        <div style="width: 100%; display: flex; flex-direction: row; justify-content: space-between;">
                            <div style="display: flex; align-items: center;">
                                <h5>Activity Details</h5>
                            </div>

                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div slot="with-padding">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label>Title</label>
                                                <input type="text" v-model="details.title" class="form-control" placeholder="Enter title" style="height: 43px;" disabled />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Priority</label>
                                                <select style="height: 44px;" class="form-control digits" v-model="details.priority" disabled>
                                                    <option v-for="(type, index) in priorities" :key="index" :value="type.value">{{ type.name }}</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <select style="height: 44px;" class="form-control digits" v-model="details.status" disabled>
                                                    <option v-for="(type, index) in taskStatus" :key="index" :value="type.value">{{ type.name }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Start date</label>
                                                <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="details.start_date" style="height: 43px;" disabled></datepicker>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>End Date</label>
                                                <datepicker placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="details.end_date" style="height: 43px;" disabled></datepicker>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="form-group">
                                                <label>Description</label>
                                                <div v-html="details.description">

                                                </div>
                                                <!-- <b-form-textarea v-model="details.description" rows="3" max-rows="8" placeholder="Enter your reason" disabled></b-form-textarea> -->
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row" v-if="details.locations.length>0">
                                        <div class="col-sm-12">
                                            <div class="mt-4" style="display: flex; gap: 16px;">
                                                <div style="width: 50%;">
                                                    <h6>Locations</h6>
                                                </div>

                                                <div style="width: 50%;">
                                                    <h6>Quantity</h6>
                                                </div>

                                            </div>

                                            <div style="display: flex; gap: 4px; margin-bottom: 12px;" v-for="(fo, index) in details.locations" :key="index">

                                                <div style="width: 50%;">
                                                    <div>
                                                        <input type="text" v-model="fo.location.name" class="form-control" placeholder="Location" style="height: 43px;" disabled />
                                                    </div>
                                                </div>
                                                <div style="width: 50%;">
                                                    <div class="w-fullbg-white rounded-sm">
                                                        <b-form-input v-model="fo.quantity" type="text" placeholder="Enter quantity" disabled style="height: 43px;"></b-form-input>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-header">
                        <div>
                            <h5>Generate Form</h5>
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <div style="display: flex; flex-direction: column;">

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.topics}">Topics</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.topics" @click="toggleProperty('topics')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.topics}" class="form-control" type="text" placeholder="Enter topics" v-model="form_data.topics">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.program_date}">Program Date</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.program_date" @click="toggleProperty('program_date')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <datepicker :class="{'low-opacity': !form.program_date}" placeholder="Select a date" input-class="datepicker-here form-control digits" v-model="form_data.program_date"></datepicker>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.event_expenditure}">Event Expenditure</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.event_expenditure" @click="toggleProperty('event_expenditure')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.event_expenditure}" class="form-control" type="text" placeholder="Enter event expenditure" v-model="form_data.event_expenditure">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.venue}">Venue</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.venue" @click="toggleProperty('venue')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.venue}" class="form-control" type="text" placeholder="Enter venue" v-model="form_data.venue">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.activity_code}">Activity Code</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.activity_code" @click="toggleProperty('activity_code')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.activity_code}" class="form-control" type="text" placeholder="Enter activity code" v-model="form_data.activity_code">
                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.budget_code}">Budget Code</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.budget_code" @click="toggleProperty('budget_code')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.budget_code}" class="form-control" type="text" placeholder="Enter budget code" v-model="form_data.budget_code">
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="row">

                                        </div> -->
                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.participants}">Participants</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.participants" @click="toggleProperty('participants')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div :class="{'low-opacity': !form.participants}">
                                                        <!-- <div class="" style="display: flex; gap: 16px;">
                                                            <div style="width: 40%;">
                                                                <h6>Category</h6>
                                                            </div>

                                                            <div style="width: 40%;">
                                                                <h6>Total</h6>
                                                            </div>

                                                            <div style="width: 20%;">
                                                                <h6>Action</h6>
                                                            </div>
                                                        </div> -->

                                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.participants" :key="index">
                                                            <div style="width: 40%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.category" type="text" placeholder="Enter category"></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 40%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.total" type="number" placeholder="Enter total"></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 20%; display: flex; align-items: center;">

                                                                <svg @click="addField(input, form_data.participants)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                </svg>

                                                                <svg v-show="form_data.participants.length > 1" @click="removeField(index, form_data.participants)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.professions}">Professions</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.professions" @click="toggleProperty('professions')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div :class="{'low-opacity': !form.professions}">
                                                        <!-- <div class="" style="display: flex; gap: 16px;">
                                                            <div style="width: 45%;">
                                                                <h6>Profession Name</h6>
                                                            </div>

                                                            <div style="width: 20%;">
                                                                <h6>Action</h6>
                                                            </div>
                                                        </div> -->

                                                        <div style="display: flex; gap: 16px; margin-bottom: 12px;" v-for="(fo, index) in form_data.professions" :key="index">
                                                            <div style="width: 45%;">
                                                                <div class="w-fullbg-white rounded-sm">
                                                                    <b-form-input v-model="fo.name" type="text" placeholder="Enter profession name"></b-form-input>
                                                                </div>
                                                            </div>

                                                            <div style="width: 20%; display: flex; align-items: center;">

                                                                <svg @click="addProfessionsField(input, form_data.professions)" width="24" height="24" class="ml-2 cursor-pointer" style="cursor: pointer; margin-right: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="green" d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z" />
                                                                </svg>

                                                                <svg v-show="form_data.professions.length > 1" @click="removeProfessionsField(index, form_data.professions)" width="24" height="24" style="cursor: pointer; margin-left: 4px;">
                                                                    <path fill="none" d="M0 0h24v24H0z" />
                                                                    <path fill="#B13032" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.monthly_income}">Monthly Income</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.monthly_income" @click="toggleProperty('monthly_income')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>

                                                    <multiselect :class="{'low-opacity': !form.monthly_income}" v-model="form_data.monthly_income" tag-placeholder="Monthly Income" placeholder="Add monthly income" label="name" :max="monthly_income_list.length" track-by="id" :options="monthly_income_list" :multiple="true" :taggable="true"></multiselect>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.age_group}">Age Group</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.age_group" @click="toggleProperty('age_group')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <multiselect :class="{'low-opacity': !form.age_group}" v-model="form_data.age_group" tag-placeholder="Age Group" placeholder="Add age group" label="name" :max="age_group_list.length" track-by="id" :options="age_group_list" :multiple="true" :taggable="true"></multiselect>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.program_objectives}">Program Objective</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.program_objectives" @click="toggleProperty('program_objectives')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.program_objectives}" v-model="form_data.program_objectives" rows="3" max-rows="8" placeholder="Enter program objective"></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.activity_in_brief}">Activity in brief</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.activity_in_brief" @click="toggleProperty('activity_in_brief')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.activity_in_brief}" v-model="form_data.activity_in_brief" rows="3" max-rows="8" placeholder="Enter activity (Upto 200 words)"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.fac_of_the_program}">Facilitator of the Program</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.fac_of_the_program" @click="toggleProperty('fac_of_the_program')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.fac_of_the_program}" class="form-control" type="text" placeholder="Facilitator of the Program" v-model="form_data.fac_of_the_program">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.outcome}">Outcome</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.outcome" @click="toggleProperty('outcome')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.outcome}" v-model="form_data.outcome" rows="3" max-rows="8" placeholder="Enter outcomes (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.challenges}">Challenges</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.challenges" @click="toggleProperty('challenges')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.challenges}" v-model="form_data.challenges" rows="3" max-rows="8" placeholder="Enter challenges (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.learnings}">Learnings</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.learnings" @click="toggleProperty('learnings')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.learnings}" v-model="form_data.learnings" rows="3" max-rows="8" placeholder="Enter learnings (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                            <div class="col-sm-6">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.recommendations}">Recommendations</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.recommendations" @click="toggleProperty('recommendations')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.recommendations}" v-model="form_data.recommendations" rows="3" max-rows="8" placeholder="Enter recommendations (Upto 100 words) maximum five bullet point"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.risk_factor}">Risk factors</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.risk_factor" @click="toggleProperty('risk_factor')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <input :class="{'low-opacity': !form.risk_factor}" class="form-control" type="text" placeholder="Enter risk factors" v-model="form_data.risk_factor">
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.remarks}">Remarks</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.remarks" @click="toggleProperty('remarks')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-textarea :class="{'low-opacity': !form.remarks}" v-model="form_data.remarks" rows="3" max-rows="8" placeholder="Enter remarks (If any)"></b-form-textarea>

                                                </div>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-sm-12">
                                                <div class="form-group">
                                                    <div class="d-flex flex-row" style="gap: 5px;">
                                                        <h6 :class="{'low-opacity': !form.report_attach}">Report Attachment</h6>
                                                        <div class="media-body text-right icon-state switch-outline">
                                                            <label class="switch">
                                                                <input type="checkbox" :checked="form.report_attach" @click="toggleProperty('report_attach')"><span class="switch-state bg-primary"></span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <b-form-file :class="{'low-opacity': !form.report_attach}" placeholder="Choose files  or drop them here..." drop-placeholder="Drop file here..." multiple :file-name-formatter="formatNames" @change="uploadAttachFile"></b-form-file>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="row mt-2">
                                        <div class="col">
                                            <div class="form-group mb-0">
                                                <button @click="onSubmit" class="btn btn-success mr-3">Create</button>
                                                <router-link :to="`/internal-activity/project-details/${details.project}`">
                                                    <button class="btn btn-danger" href="#">Go Back</button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Container-fluid Ends-->
</template>

<script>
import {
    mapState
} from 'vuex';
import Datepicker from 'vuejs-datepicker';
import vue2Dropzone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import Multiselect from 'vue-multiselect';
import js from '../../../auth/js';
export default {
    components: {
        vueDropzone: vue2Dropzone,
        Datepicker,
        Multiselect
    },

    data() {
        return {

            form: {

                venue: true,
                activity_code: true,
                budget_code: true,
                program_date: true,
                participants: true,
                professions: true,
                monthly_income: true,
                age_group: true,
                topics: true,
                event_expenditure: true,
                program_objectives: true,
                activity_in_brief: true,
                fac_of_the_program: true,
                outcome: true,
                challenges: true,
                learnings: true,
                recommendations: true,
                risk_factor: true,
                remarks: true,
                report_attach: true

            },
            form_data: {
                activity: null,
                venue: null,
                activity_code: null,
                budget_code: null,
                program_date: null,
                participants: [{
                    category: null,
                    total: null
                }],
                professions: [{
                    name: null,
                }],
                monthly_income: [],
                age_group: [],
                topics: null,
                event_expenditure: null,
                program_objectives: null,
                activity_in_brief: null,
                fac_of_the_program: null,
                outcome: null,
                challenges: null,
                learnings: null,
                recommendations: null,
                risk_factor: null,
                remarks: null,
                

            },
            report_attach:null,
            priorities: [{
                    name: 'LOW',
                    value: 1,
                },
                {
                    name: 'MEDIUM',
                    value: 2,
                },
                {
                    name: 'HIGH',
                    value: 3,
                }
            ],

            taskStatus: [{
                    name: 'Pending',
                    value: 'PENDING',
                },
                {
                    name: 'In Progress',
                    value: 'IN-PROGRESS',
                },
                {
                    name: 'Hold',
                    value: 'HOLD',
                },
                {
                    name: 'Completed',
                    value: 'COMPLETED',
                }
            ],

            monthly_income_list: [{
                    id: '1',
                    name: '3000-5000',
                },
                {
                    id: '2',
                    name: '5001-7000',
                },
                {
                    id: '3',
                    name: '7001-9000',
                },
                {
                    id: '4',
                    name: '9001-11000',
                },
                {
                    id: '5',
                    name: '11001-13000',
                },
                {
                    id: '6',
                    name: '13001-15000',
                },
                {
                    id: '7',
                    name: '15000>',
                },

            ],

            age_group_list: [{
                    id: '1',
                    name: '<10',
                },
                {
                    id: '2',
                    name: '10-14',
                },
                {
                    id: '3',
                    name: '15-18',
                },
                {
                    id: '4',
                    name: '19-24',
                },
                {
                    id: '5',
                    name: '25-29',
                },
                {
                    id: '6',
                    name: '<35-50',
                },
                {
                    id: '7',
                    name: '51>',
                },

            ],

            activity_locations: [{
                location_id: null,
                quantity: null,
            }],
        }
    },

    computed: {
        ...mapState({
            details: state => state.bnps_project.activity_detail,
            // form: state => state.bnps_project.form,

        }),

    },

    mounted() {
        if (this.$route.query.activity) {
            this.$store.dispatch("bnps_project/fetchActivityDetails", this.$route.query.activity)

        }
        // this.$store.dispatch("bnps_project/fetchForm", this.$route.params.id)

    },

    watch: {

        // form(newValue, oldValue) {
        //     if (this.form !== null) {
        //         this.form_data.policy = this.$route.params.id
        //         this.form_data.form_name = this.form[0].form_name
        //         this.form_data.submission_form = this.form[0].submission_form
        //     }
        // }
    },

    methods: {
        formatNames(files) {
            return files.length === 1 ? files[0].name : `${files.length} files selected`
        },
        uploadAttachFile(e) {
            // alert(JSON.stringify(e.target.files))
            // console.log(e.target.files)
            this.report_attach = e.target.files

        },
        toggleProperty(property) {
            if (this.form.hasOwnProperty(property)) {
                this.form[property] = !this.form[property];
            }
        },

        addField(value, fieldType) {
            fieldType.push({
                category: null,
                total: null
            });
        },

        removeField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        addProfessionsField(value, fieldType) {
            fieldType.push({
                name: null,
            });
        },

        removeProfessionsField(index, fieldType) {
            fieldType.splice(index, 1);
        },

        onSubmit() {
            let project_id = this.details.project
            this.$store.dispatch("bnps_project/addForm", {
                form: this.form,
                location: this.$route.params.id
            }).then(response => {
                if (response.code == 200) {
                    Swal.fire({
                        title: "Success!",
                        text: response.response,
                        icon: "success"
                    });

                    setTimeout(() => this.$router.push(`/internal-activity/project-details/${project_id}`), 2000)

                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.response,
                        icon: "error"
                    });
                }
            })
        }
    }
};
</script>

<style scoped>
.low-opacity {
    opacity: 0.5;
}
</style>
