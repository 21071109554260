
var host = "https://api-bnps.smicee.com";

const { h } = require("vue");

module.exports = {
    V1API: {
        'getAccessToken': host + '/api/authentication/token',
        'refreshAccessToken': host + '/client/token/refresh',
        'verifyLogin': host + '/api/authentication/token/verify',
        // 'reset_password_client':host+'/client/change-password',

        'create_sms':host+'/client/sms-template/create',
        'get_sms':host+'/client/sms-template/get',
        'update_sms':host+'/client/sms-template/update/',
        'delete_sms':host+'/client/sms-template/delete/',

        //My Updates
        'get_my_updates':host+'/api/my-updates/refer/list',
        'read_my_updates':host+'/api/my-updates/refer/activity/read/',
        'get_my_contact':host+'/api/access-control/user-accounts/contact-list',

        //Reset Password
        'update_password':host+'/api/authentication/change-password',
        'email_check':host+'/api/authentication/check-user',
        'verify_otp':host+'/api/access-control/reset-password/verify/otp',
        'reset_password':host+'/api/authentication/reset-password',
        'otp_list':host+'/api/access-control/otp/list',

        // Porject
        'create_project': host+ '/api/project/create',
        'edit_project':host+'/api/project/edit/',
        'get_project': host+ '/api/project/list',
        'update_project':host+'/api/project/edit/',
        'get_project_detail': host+ '/api/project/details/',
        'get_my_project':host+'/api/project/my-list',
        'assign_project_employee': host+ '/api/project/employee/create',
        'get_my_task':host+'/api/project/task/my-list',
        'get_my_task_deadline':host+'/api/project/task/my-list/deadline-today',
        'delete_teammate_from_projects':host+'/api/project/employee/delete/',
        'budget_by_project':host+'/api/budget-management/request/list/by-project/',
        'quotation_by_project':host+'/api/quotation/list/by-project/',
        'invoice_by_project':host+'/api/invoice/list/by-project/',
        'requisition_by_project':host+'/api/finance/requisition/list/by-project/',
        'purchase_order_by_project':host+'/api/purchase-order/list/by-project/',
        'bill_list_by_project':host+'/api/bill/list/by-project/',
        'rfp_list_by_project':host+'/api/rfp/list/by-project/',
        'update_project_priority':host+'/api/project/edit-priority-status/',
        'add_client_to_project':host+'/api/project/client/create',
        'delete_client_from_project':host+'/api/project/client/delete/',
        'client_list_by_project':host+'/api/project/client/list/by-project/',
        'delete_project':host+'/api/project/delete/',
        'project_member_list':host+'/api/project/employee/by-project/',
        'project_task_member_list':host+'/api/project/task/employee/by-task/',

        'add_vendor_to_project':host+'/api/project/vendor/create',
        'delete_vendor_from_project':host+'/api/project/vendor/delete/',
        'vendor_list_by_project':host+'/api/project/vendor/list/by-project/',
        'create_project_attachment':host+'/api/project/add-attachment',
        'delete_attachment':host+'/api/project/delete-attachment/',
        'check_my_password':host+'/api/authentication/check-password',
        'project_list_by_employee':host+'/api/project/list/by-employee/',

        'project_search':host+'/api/project/list/search',
        'my_project_search':host+'/api/project/my-list/search',
        
        //eod
        'eod_create':host+'/api/eod/create',
        'eod_draft_create':host+'/api/eod/draft/create',
        'get_my_draft':host+'/api/eod/draft/my-draft',
        'eod_draft_edit':host+'/api/eod/draft/edit/',
        'eod_draft_delete':host+'/api/eod/draft/delete/',
        'get_my_eod_list':host+'/api/eod/my-list',
        'get_eod_list_by_employee':host+'/api/eod/list/by-employee/',
        'get_all_eod':host+'/api/eod/list',
        'get_eod_details':host+'/api/eod/details/',
        'eod_delete':host+'/api/eod/delete/',
        'eod_add_refer':host+'/api/eod/add-refer/',
        'eod_delete_refer':host+'/api/eod/delete-refer/',
        'get_reply_of_eod':host+'/api/eod/reply/list/by-eod/',
        'add_eod_reply':host+'/api/eod/reply/create',
        'edit_eod_status':host+'/api/eod/edit-status/',
        'eod_completed_task_tickets':host+'/api/eod/completed/task-ticket',

        // Task
        'get_task_category': host+ '/api/project/task/list/by-project/sort-label/',
        'create_task_category': host+ '/api/project/tasks/label/create',
        'get_task_list': host+ '/api/project/task/list/by-project/',
        'get_task_details': host+ '/api/project/task/details/',
        'create_task': host+ '/api/project/task/create',
        'update_task': host+ '/api/project/task/edit/',
        'delete_task': host+ '/api/project/task/delete/',
        'get_sub_task_list': host+ '/api/project/task/sub-task/list/',
        'assign_task_employee': host+ '/api/project/task/employee/create',
        'delete_assigned_employee': host+ '/api/project/task/employee/delete/',
        'create_task_with_label': host+ '/api/project/task/create/with/label-name',
        'update_task_with_label': host+ '/api/project/task/edit-label',
        'toggle_task_priority': host+ '/api/project/task/edit-priority-status/',
        'toggle_subtask_complete_status': host+ '/api/project/task/toggle-complete/',
        'toggle_task_complete_status': host+'/api/project/task/toggle-complete-status/parent/',
        'toogle_subtask_complete': host+'/api/project/task/toggle-complete-status/sub/',
        'update_task_label': host+'/api/project/tasks/label/update',
        'delete_task_label': host+'/api/project/tasks/label/delete',

        // Task --> Comments
        'get_comments': host + '/api/project/task/comments/by-task/',
        'create_comment': host + '/api/project/task/comments/create',

        // Client
        'create_company': host+ '/api/client/company/create',
        'get_company': host+ '/api/client/company/list',
        'create_client': host+ '/api/client/create',
        'get_client': host+ '/api/client/list',
        'get_client_without_pg': host+ '/api/client/list-short',
        'delete_client': host+ '/api/client/delete/',
        'client_details':host+'/api/client/details/',
        'company_details':host+'/api/client/company/details/',
        'client_by_user_id':host+'/api/client/details-short/',
        'update_client':host+'/api/client/edit/',

        // Vendor Contact
        'create_vendor': host+'/api/vendor/create',
        'get_vendor': host+'/api/vendor/list',
        'get_vendor_without_pg': host+'/api/vendor/list-short',
        'delete_vendor': host+ '/api/vendor/delete/',
        'vendor_edit':host+'/api/vendor/edit/',
        'toggle_vendor_status':host+'/api/vendor/toggle-status/',
        'venodr_details':host+'/api/vendor/details/',
        'vendor_by_user_id':host+'/api/vendor/details-short/',

        'vendor_contact_create':host+'/api/vendor/contact-book/create',
        'vendor_contact_edit':host+'/api/vendor/contact-book/edit/',
        'get_vendor_contact':host+'/api/vendor/contact-book/list',
        'delete_vendor_contact':host+'/api/vendor/contact-book/delete/',
        'vendor_contact_label_create':host+'/api/vendor/contact-book/label/create',
        'venodr_contact_label_lists':host+'/api/vendor/contact-book/labels/list',
        'vendor_contact_label_delete':host+'/api/vendor/contact-book/delete-label/',
        'vendor_contact_label_add':host+'/api/vendor/contact-book/add-label/',
        'vendor_label_delete':host+'/api/vendor/contact-book/label/delete/',

        'add_vendor_category':host+'/api/vendor/category/create',
        'edit_vendor_category':host+'/api/vendor/category/edit/',
        'delete_vendor_category':host+'/api/vendor/category/delete/',
        'get_vendor_categories':host+'/api/vendor/category/list',

        'vendor_request_for_quotation':host+'/api/rfp/vendor-quotation/create',
        'vendor_rfq_list':host+'/api/rfp/list/by-vendor',

        'project_list_by_vendor':host+'/api/project/list/by-vendor/',
        'vendor_project_details':host+'/api/project/details/by-vendor/',
        'vendor_purchase_order_by_project':host+'/api/purchase-order/list/by-project-vendor/',
        'vendor_bill_by_project':host+'/api/bill/list/by-project-vendor/',

        'po_list_by_vendor':host+'/api/purchase-order/list/by-vendor',
        'bill_list_by_vendor':host+'/api/bill/list/by-vendor',

        // Lead
        'create_lead': host+ '/api/client/leads/create',
        'get_lead': host+ '/api/client/leads/list',
        'delete_lead': host+ '/api/client/leads/delete/',
        'edit_lead': host+ '/api/client/leads/edit/',

        // Lead --> Label
        'create_label': host+ '/api/client/leads/label/create',
        'get_label': host+ '/api/client/leads/labels/list',
        'delete_label': host+ '/api/client/leads/label/delete/',
        'add_label_to_lead': host+ '/api/client/leads/add-label/',
        'delete_label_from_lead':host +'/api/client/leads/delete-label/',

        // Items
        'create_item': host+ '/api/item/create',
        'get_item': host+ '/api/item/list',
        'delete_item': host+ '/api/item/delete/',
        'edit_item': host+ '/api/item/edit/',

        //Holiday
        'create_holiday':host+'/api/holiday/create',
        'get_holiday': host+'/api/holiday/list',
        'delete_holiday':host +'/api/holiday/delete/',
        'edit_holiday':host +'/api/holiday/edit/',

        // RFP
        'create_rfp_category': host + '/api/rfp/category/create',
        'get_rfp_category': host + '/api/rfp/category/list',
        'delete_rfp_category': host + '/api/rfp/category/delete/',

        'create_rfp': host + '/api/rfp/create',
        'get_rfp': host + '/api/rfp/list',
        'get_rfp_list_by_category':host+'/api/rfp/list/by-category/',

        'get_rfp_by_search':host+'/api/rfp/search/list',

        'delete_rfp': host + '/api/rfp/delete/',
        'get_rfp_details': host + '/api/rfp/details/',
        'edit_rfp': host + '/api/rfp/edit/',
        'rfp_active_status_toggle':host+'/api/rfp/toggle-status/',

        'get_rfp_form':host+'/api/rfp/form/by-rfp/',
        'create_rfp_form':host+'/api/rfp/form/create',
        'submit_rfp_form':host+'/api/rfp/form/submission/create',
        'delete_rfp_form_responses':host+'/api/rfp/form/submission/delete/',
        'get_rfp_form_responses':host+'/api/rfp/form/submission/by-form/',
        'rfp_list_user_submitted_form_only':host+'/api/rfp/list/user-submitted',
        'rfp_response_by_form':host+'/api/rfp/form/submission/by-form/by-user/',
        'rfp_submission_label_add':host+'/api/rfp/form/submission/label/create',
        'rfp_submission_label_delete':host+'/api/rfp/form/submission/label/delete/',
        'rfp_submission_label_list':host+'/api/rfp/form/submission/labels/list',
        'rfp_submission_add_label':host+'/api/rfp/submission/add-label/',
        'rfp_submission_delete_label':host+'/api/rfp/submission/delete-label/',
        'rfp_submission_details':host+'/api/rfp/form/submission/detail/',
        'rfp_submission_reply_create':host+'/api/rfp/submission/reply/create',
        'get_rfp_submission_reply':host+'/api/rfp/submission/reply/list/by-submission/',


        // Finance
        // Quotation
        'create_quotation': host+ '/api/quotation/create',
        'get_quotation': host+ '/api/quotation/list',
        'delete_quotation': host+ '/api/quotation/delete/',
        'edit_quotation': host+ '/api/quotation/edit/',
        'get_quotation_details': host+ '/api/quotation/details/primary/',
        'quotation_add_attachment':host+'/api/quotation/add-attachment',
        'quotation_delete_attachment':host+'/api/quotation/delete-attachment/',

        // Invoice
        'create_invoice': host+ '/api/invoice/create',
        'get_invoice': host+ '/api/invoice/list',
        'delete_invoice': host+ '/api/invoice/delete/',
        'edit_invoice': host+ '/api/invoice/edit/',
        'get_invoice_details': host+ '/api/invoice/details/',
        'invoice_attachment_add':host+'/api/invoice/add-attachment',
        'invoice_attachment_delete':host+'/api/invoice/delete-attachment/',
        'record_invoice_payment':host+'/api/invoice/payments/create',
        'invocie_payment_list_by_invoice':host+'/api/invoice/payments/list-by-invoice/',
        'invoice_payment_list_status_change':host+'/api/invoice/payments/edit-status/',
        'invocie_payments':host+'/api/invoice/payments/list',
        'delete_invoice_payments':host+'/api/invoice/payments/delete/',

        // Purchase Order
        'create_purchase_order': host+ '/api/purchase-order/create',
        'get_purchase_order': host+ '/api/purchase-order/list',
        'delete_purchase_order': host+ '/api/purchase-order/delete/',
        'edit_purchase_order': host+ '/api/purchase-order/edit/',
        'get_purchase_order_details': host+ '/api/purchase-order/details/primary/',
        'add_po_attachment':host+'/api/purchase-order/add-attachment',
        'delete_po_attachment':host+'/api/purchase-order/delete-attachment/',
        'purchase_order_add_reply':host+'/api/purchase-order/reply/create',
        'get_replies_of_po':host+'/api/purchase-order/reply/list/by-po/',

        // Bill
        'create_bill': host+ '/api/bill/create',
        'get_bill': host+ '/api/bill/list',
        'delete_bill': host+ '/api/bill/delete/',
        'edit_bill': host+ '/api/bill/edit/',
        'get_bill_details': host+ '/api/bill/details/primary/',
        'add_bill_attachment':host+'/api/bill/add-attachment',
        'delete_bill_attahment':host+'/api/bill/delete-attachment/',

        'record_bill_payment':host+'/api/bill/payments/create',
        'bill_payment_list_by_bill':host+'/api/bill/payments/list-by-bill/',
        'bill_payment_list_status_change':host+'/api/bill/payments/edit-status/',
        'bill_payments':host+'/api/bill/payments/list',
        'delete_bill_payments':host+'/api/bill/payments/delete/',
        'bill_add_reply':host+'/api/bill/reply/create',
        'get_replies_of_bill':host+'/api/bill/reply/list/by-bill/',

        // Accounts
        // Category
        'create_account_category': host+ '/api/finance/category/create',
        'get_account_category': host+ '/api/finance/category/list',
        'delete_account_category': host+ '/api/finance/category/delete',
        'edit_account_category': host+ '/api/finance/category/edit',
        'edit_account_serial_category': host+ '/api/finance/category',

        //group
        'create_account_group': host+ '/api/finance/group/create',
        'get_account_group': host+ '/api/finance/group/list',
        'get_account_group_by_category': host+ '/api/finance/group/list/by-category',
        'delete_account_group': host+ '/api/finance/group/delete',
        'edit_account_group': host+ '/api/finance/group/edit',

        //ledger
        'create_account_ledger': host+ '/api/finance/ledger/create',
        'get_account_ledger': host+ '/api/finance/ledger/list',
        'get_account_ledger_details': host+ '/api/finance/ledger/details',
        // 'get_account_ledger_by_group': host+ '/api/finance/ledger/list/by-group',
        'get_account_ledger_by_group': host+ '/api/finance/ledger/list/post/by-group',
        'delete_account_ledger': host+ '/api/finance/ledger/delete',
        'edit_account_ledger': host+ '/api/finance/ledger/edit',

        //note
        'create_account_note': host+ '/api/finance/note/create',
        'get_account_note': host+ '/api/finance/note/list',
        'get_account_note_details': host+ '/api/finance/note/details',
        'delete_account_note': host+ '/api/finance/note/delete',
        'edit_account_note': host+ '/api/finance/note/edit',
        'note_add_ledger':host+'/api/finance/notes/add-ledger/element',
        'note_delete_ledger':host+'/api/finance/notes/delete-ledger/element',

        //Transaction
        'record_transaction':host+'/api/finance/transaction/create',
        'get_transactions':host+'/api/finance/transaction/list/detailed',
        'update_transactions_note':host+'/api/finance/transaction/edit/',
        'get_transactions_by_ledger':host+'/api/finance/transaction/by-ledger',
        'get_transactions_by_jid':host+'/api/finance/transaction/list/detailed/by-id',
        'get_trial_balance':host+'/api/finance/trial-balance',

        //incomeStruture
        'income_structure': host+'/api/finance/income-statement/ledger/by-element',
        'income_structure_list': host+'/api/finance/income-statement/ledger/list',
        'income_structure_add_ledger':host+'/api/finance/income-statement/add-ledger/element',
        'income_structure_delete_ledger':host+'/api/finance/income-statement/delete-ledger/element',
        'income_statement':host+'/api/finance/income-statement/details',
        'income_statement_calculated':host+'/api/finance/income-statement/calculated',

        //balanceSheet
        'balance_sheet_element_structure': host+'/api/finance/balance-sheet/element/list',
        'balance_sheet': host+'/api/finance/balance-sheet/details',

        //Budget
        'create_budget_category': host+ '/api/budget-management/category/create',
        'request_budget': host+ '/api/budget-management/request/create',
        'request_budget_update': host+ '/api/budget-management/request/edit',
        'get_budget_requests': host+ '/api/budget-management/request/list',
        'get_budget_category': host+ '/api/budget-management/category/list',
        'delete_budget_category': host+ '/api/budget-management/category/delete',
        'edit_budget_category': host+ '/api/budget-management/category/edit',
        'add_refer_to_budget':host+'/api/budget-management/request/add-refer/',
        'delete_refer_from_budget':host+'/api/budget-management/request/delete-refer/',
        'get_budget_reply':host+'/api/budget-management/request/reply/list/by-br/',
        'budget_reply_add':host+'/api/budget-management/request/reply/create',
        'budget_request_detail':host+'/api/budget-management/request/details/',

        // Requisition
        'create_requisition': host+ '/api/finance/requisition/create',
        'get_requisition': host+ '/api/finance/requisition/list',
        'delete_requisition': host+ '/api/finance/requisition/delete/',
        'edit_requisition': host+ '/api/finance/requisition/edit/',
        'edit_requisition_status': host+ '/api/finance/requisition/edit-status',
        'get_requisition_details': host+ '/api/finance/requisition/details/',
        'requisition_add_attachment':host+'/api/finance/requisition/add-attachment',
        'requisition_delete_attachment':host+'/api/finance/requisition/delete-attachment/',
        'my_requisition':host+'/api/finance/requisition/my-list',
        'requisition_add_refer':host+'/api/finance/requisition/request/add-refer/',
        'requisition_delete_refer':host+'/api/finance/requisition/request/delete-refer/',

        'add_requisition_type':host+'/api/finance/requisition/type/create',
        'delete_requisition_type':host+'/api/finance/requisition/type/delete/',
        'requisition_type_list':host+'/api/finance/requisition/type/list',

        //Requisition Record Payment
        'requisition_record_payment':host+'/api/finance/requisition/payments/create',
        'requisition_payment_list_by_requisition':host+'/api/finance/requisition/payments/list-by-requisition/',
        'requisition_payemnt_list_status_change':host+'/api/finance/requisition/payments/edit-status/',
        'requisition_payments':host +'/api/finance/requisition/payments/list',
        'delete_requisition_payments':host +'/api/finance/requisition/payments/delete/',

        // Banking Details
        'bank_list':host+'/api/finance/bank-info/list',
        'active_bank_list':host+'/api/finance/bank-info/list-active',
        'bank_create':host+'/api/finance/bank-info/create',
        'bank_details':host+'/api/finance/bank-info/details/',
        'edit_bank':host +'/api/finance/bank-info/edit/',
        'bank_delete':host +'/api/finance/bank-info/delete/',
        'toggle_active':host +'/api/finance/bank-info/toggle-status/',
        'bank_add_attachment':host+'/api/finance/bank-info/add-attachment',
        'bank_delete_attachment':host+'/api/finance/bank-info/delete-attachment/',

        //user list
        'user_list':host+'/api/authentication/user/list',

        // HR & Admin
        // TimeSheet ->PUNCH
        'employee_punch':host+'/api/access-control/user-accounts/punch',
        'latest_timesheet':host+'/api/access-control/user-accounts/time-sheet/today/latest',
        'time_sheet_summary':host+'/api/access-control/user-accounts/time-sheet/summary',
        'work_history_time_sheet_by_id':host+'/api/access-control/user-accounts/time-sheet/dates/',
        'details_time_sheet_by_date_and_id':host+'/api/access-control/user-accounts/time-sheet/',

        // Compliance
        'create_compliance': host+ '/api/compliance/create',
        'get_compliance': host+ '/api/compliance/list/short',
        'get_compliance_withpg': host+ '/api/compliance/list',
        'delete_compliance': host+ '/api/compliance/delete/',
        'edit_compliance': host+ '/api/compliance/edit/',
        'compliance_details': host+ '/api/compliance/details/',
        'create_compliance_form': host+ '/api/compliance/form/create',
        'get_compliance_form': host+ '/api/compliance/form/by-compliance/',
        'submit_compliance_form': host+ '/api/compliance/form/submission/create',
        'get_compliance_form_responses': host+ '/api/compliance/form/submission/by-form/',
        'delete_compliance_form_response':host+'/api/compliance/form/submission/delete/',
        'get_compliance_form_list':host+'/api/compliance/form',

        // Policy
        'create_policy': host+ '/api/policy/create',
        'get_policy': host+ '/api/policy/list/short',
        'get_policy_withpg': host+ '/api/policy/list',
        'delete_policy': host+ '/api/policy/delete/',
        'edit_policy': host+ '/api/policy/edit/',
        'policy_details': host+ '/api/policy/details/',
        'create_policy_form': host+ '/api/policy/form/create',
        'get_policy_form': host+ '/api/policy/form/by-policy/',
        'get_policy_form_detail':host+'/api/policy/form/detail/',
        'submit_policy_form': host+ '/api/policy/form/submission/create',
        'get_policy_form_responses': host+ '/api/policy/form/submission/by-form/',
        'delete_policy_form_responses':host+'/api/policy/form/submission/delete/',
        'get_policy_form_list':host+'/api/policy/form',

        //Grievance
        'create_grievance':host+'/api/grievance/create',
        'update_grievance':host+'/api/grievance/edit/',
        'get_grievance':host+'/api/grievance/list',
        'delete_grievance':host+'/api/grievance/delete/',
        'add_refer_to_grievance':host+'/api/grievance/add-refer/',
        'delete_refer_to_grievance':host+'/api/grievance/delete-refer/',
        'get_replies_of_grievance':host+'/api/grievance/reply/list/by-grievance/',
        'add_grievance_reply':host+'/api/grievance/reply/create',
        'update_grievance_status':host+'/api/grievance/edit-status',
        'grievance_details':host+'/api/grievance/details/',

        //Leave Type
        'create_leave_type':host+'/api/leave/type/create',
        'update_leave_type':host+'/api/leave/type/edit/',
        'get_leave_type_list': host+ '/api/leave/type/list',
        'delete_leave_type': host+'/api/leave/type/delete/',
        'update_leave_status': host+'/api/leave/edit-status',
        'add_refer_to_leave':host + '/api/leave/add-refer/',
        'delete_refer_to_leave':host+'/api/leave/delete-refer/',
        'get_replies_of_leave':host+'/api/leave/reply/list/by-leave/',
        'add_leave_reply':host+'/api/leave/reply/create',
        'get_remaining_leave':host+'/api/leave/remaining-days',
        'leave_approve':host+'/api/leave/approve/',
        'leave_detail':host+'/api/leave/details/',
        'get_leave_employee':host+'/api/leave/application/list/by-employee/',
        'delete_leave_request':host+'/api/leave/delete/',
        'leave_remaining_user':host+'/api/leave/admin-dash/remaining-days/',
        'leave_create_by_admin':host+'/api/leave/create/by-admin',

        // Payroll
        'get_payroll_items':host+'/api/payroll/item/list',
        'create_payroll_item':host+'/api/payroll/item/create',
        'edit_payroll_item':host+'/api/payroll/item/edit/',
        'delete_payroll_item':host+'/api/payroll/item/delete/',
        'get_inclusive_payroll_items':host+'/api/payroll/item/list/by-status/INCLUSIVE',
        'get_deductive_payroll_items':host+'/api/payroll/item/list/by-status/DEDUCTIVE',

        'get_designation_payroll':host+'/api/payroll/designation-payroll/list',
        'create_designation_payroll':host+'/api/payroll/designation-payroll/create',
        'edit_designation_payroll':host+'/api/payroll/designation-payroll/edit/',
        'delete_designation_payroll':host+'/api/payroll/designation-payroll/delete/',

        'get_employee_payroll':host+'/api/payroll/employee-payroll/list',
        'create_employee_payroll':host+'/api/payroll/employee-payroll/create',
        'edit_employee_payroll':host+'/api/payroll/employee-payroll/edit/',
        'delete_employee_payroll':host+'/api/payroll/employee-payroll/delete/',
        'get_payroll_by_designation':host+'/api/payroll/designation-payroll/list/by-designation/',
        'get_payroll_details':host+'/api/payroll/employee-payroll/details/',
        'get_payroll_history':host+'/api/payroll/employee-payroll/history/',
        'toggle_payroll_active':host+'/api/payroll/employee-payroll/toggle-active/',

        //Organization
        //Department
        'get_all_departments':host+'/api/access-control/departments',
        'create_department':host+'/api/access-control/departments/create',
        'update_department':host+'/api/access-control/departments/edit/',
        'delete_department':host+'/api/access-control/departments/delete/',

        //Designations
        'get_all_designations':host+'/api/access-control/designations',
        'create_designation':host+'/api/access-control/designations/create',
        'update_designation':host+'/api/access-control/designations/edit/',
        'delete_designaton':host+'/api/access-control/designations/delete/',
        'get_designation_by_depratment':host+'/api/access-control/designations/by-department/',
        'designation_permission':host+'/api/access-control/designation-permission',
        'toggle_designation_permission':host+'/api/access-control/designation-permission-toggle',

        //Employee
        'get_all_employee':host+'/api/access-control/user-accounts',
        'get_all_employee_withpg':host+'/api/access-control/user-accounts/list',
        'employee_details':host+'/api/access-control/user-accounts/employee-info/',
        'create_employee':host+'/api/access-control/user-accounts/create',
        'update_employee_information':host+'/api/access-control/user-accounts/edit/full/',
        'update_employee_delete':host+'/api/access-control/user-accounts/delete',
        'my_employee_details':host+'/api/access-control/user-accounts/my-details',
        'employee_add_attachment':host+'/api/access-control/user-accounts/add-attachment',
        'employee_delete_attachment':host+'/api/access-control/user-accounts/delete-attachment/',

        //Leave Apply
        'get_all_leave_list': host+'/api/leave/list',
        'get_my_leave_list':host+'/api/leave/application/my-list',
        'get_todays_leaves_count': host+'/api/leave/count/today',
        'apply_leave':host+'/api/leave/apply',
        'update_leave':host+'/api/leave/edit/',
        'delete_leave_apply':host+'/api/leave/delete/',

        //Trainer
        'get_all_trainer': host+'/api/training/trainer/list',
        'create_trainer': host+'/api/training/trainer/create',
        'update_trainer':host+'/api/training/trainer/edit/',
        'delete_trainer':host+'/api/training/trainer/delete/',
        'toggle_trainer_status':host+'/api/training/trainer/toggle-status/',

        //Training
        'get_training_lits':host+'/api/training/list',
        'create_training':host+'/api/training/create',
        'update_training':host+'/api/training/edit/',
        'delete_training':host+'/api/training/delete/',
        'toggle_training_status': host+'/api/training/toggle-status/',
        'assign_employee_training': host+'/api/training/employee/create',
        'delete_assign_employee': host+'/api/training/employee/delete/',
        'get_my_trainings': host+'/api/training/my-list',

        //Training Type
        'get_training_type':host+'/api/training/type/list',
        'create_training_type':host+'/api/training/type/create',
        'update_training_type':host+'/api/training/type/edit/',
        'delete_training_type':host+'/api/training/type/delete/',
        'toggle_training_type_status':host+'/api/training/type/toggle-status/',

        //Ticket
        'get_ticket':host+'/api/ticket/list',
        'get_my_ticket':host+'/api/ticket/list/by-user',
        'create_ticket':host+'/api/ticket/create',
        'edit_ticket':host+'/api/ticket/edit/',
        'delete_ticket':host+'/api/ticket/delete/',
        'update_ticket_status':host+'/api/ticket/edit-ticket-status/',
        'update_ticket_priority':host+'/api/ticket/edit-priority-status/',
        'get_refer_ticket':host+'/api/ticket/list/refered',
        'add_refer_to_ticket':host+'/api/ticket/add-refer/',
        'delete_refer_to_ticket':host+'/api/ticket/delete-refer/',
        'get_ticket_details':host+'/api/ticket/details/',
        'get_ticket_list_employee':host+'/api/ticket/list/refered/by-employee/',

        //Asset
        'create_asset':host+'/api/assets/create',
        'update_asset':host+'/api/assets/edit/',
        'asset_details': host+'/api/assets/details/',
        'get_asset_list':host+'/api/assets/list',
        'delete_asset':host+'/api/assets/delete/',
        'get_my_asset':host+'/api/assets/allocation/my-list',
        'asset_allocation_list':host+'/api/assets/allocation/list',
        'asset_allocation_delete':host+'/api/assets/allocation/delete/',
        'asset_allocation_create': host +'/api/assets/allocation/create',

        'asset_add_attachment':host+'/api/assets/add-attachment',
        'asset_delete_attachment':host+'/api/assets/delete-attachment/',
        'get_employee_asset':host+'/api/assets/allocation/by-employee/',

        'create_asset_type':host+'/api/assets/type/create',
        'get_asset_type':host+'/api/assets/type/list',
        'update_asset_type':host+'/api/assets/type/edit/',
        'delete_asset_type':host+'/api/assets/type/delete/',

        //Job
        'job_create':host+'/api/job/create',
        'get_job_list':host+'/api/job/list',
        'get_job_count':host+'/api/job/count',
        'get_job_details':host+'/api/job/details/',
        'update_job':host+'/api/job/edit/',
        'delete_job':host+'/api/job/delete/',
        'update_job_status':host+'/api/job/edit-job-status/',
        'get_job_form':host+'/api/job/form/by-job/',
        'create_job_form':host+'/api/job/form/create',
        'add_job_category':host+'/api/job/category/create',
        'edit_job_category':host+'/api/job/category/edit/',
        'get_job_categories':host+'/api/job/category/list',
        'delete_job_category':host+'/api/job/category/delete/',
        'job_submit_form':host+'/api/job/applicant/form/submission/create',
        'get_job_form_responses':host+'/api/job/applicant/form/submission/by-form/',
        'delete_job_form_responses':host+'/api/job/applicant/form/submission/delete/',
        'job_submission_details':host+'/api/job/applicant/form/submission/detail/',


        //Applicant Labels
        'create_applicant_label':host+'/api/job/applicant/label/create',
        'get_applicant_label':host+'/api/job/applicant/labels/list',
        'delete_applicant_label':host+'/api/job/applicant/label/delete/',

        'add_label_to_applicant':host+'/api/job/applicant/add-label/',
        'delete_applicant_from_label':host+'/api/job/applicant/delete-label/',


        //Candidate
        'create_candidate':host+'/api/job/candidate/register',

        //Applicants
        'create_applicant': host+'/api/job/applicant/create',
        'get_user_applicant_list':host+'/api/job/applicant/application/list',
        'get_applicant_list_by_job': host+'/api/job/applicant/by-job/list/',
        'get_applicant':host+'/api/job/applicant/list',
        'get_applicant_details':host+'/api/job/applicant/details/',

        //Ticket Reply
        'get_ticket_reply':host+'/api/ticket/reply/list',
        'get_replies_of_ticket':host+'/api/ticket/reply/list/by-ticket/',
        'add_ticket_reply':host+'/api/ticket/reply/create',
        'edit_ticket_reply':host+'/api/ticket/reply/edit/',
        'delete_ticket_reply':host+'/api/ticket/reply/delete/',

        //Organization Setting
        'create_organizaton_setting':host+'/api/org-settings/create',
        'get_organization_setting':host+'/api/org-settings/view',
        'create_organization_attachment':host+'/api/org-settings/add-attachment',
        'delete_organization_attachment':host+'/api/org-settings/delete-attachment/',

        //On Boarding
        'on_boarding_list':host+'/api/onboarding/list',
        'on_board_compliance_create':host+'/api/onboarding/compliance/create',
        'on_board_policy_create':host+'/api/onboarding/policy/create',
        'on_board_compliance_delete':host+'/api/onboarding/compliance/delete/',
        'on_board_policy_delete':host+'/api/onboarding/policy/delete/',
        'on_board_compliance_list':host+'/api/onboarding/compliance/list',
        'on_board_policy_list':host+'/api/onboarding/policy/list',

        'compliance_form_list_by_employee':host+'/api/onboarding/compliance/list/by-type/1/',
        'policy_form_list_by_employee':host+'/api/onboarding/policy/list/by-type/1/',
        'on_board_compliance_submission_check':host+'/api/compliance/form/submission/by-form/by-user/',
        'on_board_policy_submission_check':host+'/api/policy/form/submission/by-form/by-user/',
        'on_board_submission_remains':host+'/api/onboarding/documents/list/by-type/1/remains',


        //Dashbord
        'my_workspace_count':host+'/api/count/my-workspace/list',
        'get_subtask_completed_percentage':host+'/api/project/task/sub-task/completion-ratio',
        'get_activity_summary':host+'/api/project/task/team-members/activity-summery',
        'get_subtask_per_day':host+'/api/project/task/sub-task/personal-growth',
        'operation_asset_count':host+'/api/count/operation-dashboard/asset',
        'operation_project_count':host+'/api/count/operation-dashboard/project',
        'operation_lead_client_count':host+'/api/count/operation-dashboard/leads/clients',
        'operation_vendor_contact_vendorCount':host+'/api/count/operation-dashboard/vendor/v-contacts',
        'operation_eod_count':host+'/api/count/operation-dashboard/eod',
        'operation_leave_count':host+'/api/count/operation-dashboard/leave',
        'operation_grievance_count':host+'/api/count/operation-dashboard/gievance',
        'operation_ticket_count':host+'/api/count/operation-dashboard/ticket',
        'operation_requisition_count':host+'/api/count/operation-dashboard/requisition',
        'operation_organization_count':host+'/api/count/operation-dashboard/organization',
        'get_calendar_data':host+'/api/project/calender-format',

        //BNPS
        

        //Location
        
        'get_location':host+'/api/ngo/location',
        'get_location_by_project':host+'/api/ngo/project-location/list/',
        'create_location':host+'/api/ngo/location/create',
        'update_location':host+'/api/ngo/location/edit/',
        'delete_location':host+'/api/ngo/location/delete/',

        //Project
        'create_project_bnps':host+'/api/ngo/project/create',
        'edit_project_bnps':host+'/api/ngo/project/edit/',
        'get_project_bnps': host+ '/api/ngo/project/list',
        'get_bnps_project_detail': host+ '/api/ngo/project/details/',
        'delete_bnps_project':host+'/api/ngo/project/delete/',
        'get_activity_location_count_by_project':host+'/api/ngo/activity/location/count/',
       

        //Activity
        'create_activity':host+'/api/ngo/activity/create',
        'activity_list_by_project':host+'/api/ngo/activity/list/by-project/',
        'edit_activity':host+'/api/ngo/activity/edit/',
        'delete_activity':host+'/api/ngo/activity/delete/',
        'activity_details':host+'/api/ngo/activity/details/',


        //Activity Form
        'create_activity_form': host+ '/api/ngo/activity/form-bool/edit/',
        'get_activity_form': host+ '/api/ngo/activity/form-bool/by-tl/',
        'get_activity_form_detail':host+'/api/ngo/activity/form/detail/',
        // 'submit_policy_form': host+ '/api/policy/form/submission/create',
        // 'get_policy_form_responses': host+ '/api/policy/form/submission/by-form/',
        // 'delete_policy_form_responses':host+'/api/policy/form/submission/delete/',

        'activity_form_submit_create':host+'/api/ngo/activity/form/submission/create',
        'activity_form_submit_edit_by_admin':host+'/api/ngo/activity/form/submission/edit/by-admin/',

        'get_activity_form_responses': host+ '/api/ngo/activity/form/submission/by-tl/',
        'delete_activity_form_responses':host+'/api/ngo/activity/form/submission/delete/',
        'activity_form_response_details':host+'/api/ngo/activity/form/submission/detail/',


        'draft_view':host+'/api/ngo/activity/form/submission/draft-view',
        'draft_create':host+'/api/ngo/activity/form/submission/draft-create',
        'draft_edit':host+'/api/ngo/activity/form/submission/draft-edit/',
        'draft_delete':host+'/api/ngo/activity/form/submission/draft-delete/',
        'pending_drafts_list':host+'/api/ngo/activity/form/submission/draft-pending/list',
        'approved_drafts_list':host+'/api/ngo/activity/form/submission/draft-approved/list',
        'update_darft_status':host+'/api/ngo/activity/form/submission/draft-status-update',
        'draft_details':host+'/api/ngo/activity/form/submission/draft-details/',

        //Objective
        'objective_list_by_project':host+'/api/ngo/objective/list/by-project/',
        'objective_create':host+'/api/ngo/objective/create',
        'objective_edit':host+'/api/ngo/objective/edit/',
        'objective_delete':host+'/api/ngo/objective/delete/',

        // Location wise Employee
        'location_wise_employee':host+'/api/access-control/user-accounts/by-location'

        
    }
};
